import {
  Alert,
  Box,
  FormControl,
  FormGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/store";
import Yup from "../utils/yup";
import { clearState, userSelector } from "../redux/user/userSlice";
import { useFormik } from "formik";
import { authenticate } from "../redux/user/userActions";
import { colors } from "../assets/themes";
import LoginCardContainer from "../components/LoginCardContainer";
import PasswordInput from "../components/Input/PasswordInput";
import urls from "../utils/urls";

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { isLoading, errorMessage } = useAppSelector(userSelector);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      dispatch(authenticate({ params: values })).then((value) => {
        if (value.meta.requestStatus !== "rejected") {
          navigate(urls.dashboard);
        }
      });
    },
    enableReinitialize: true,
  });

  return (
    <LoginCardContainer>
      <Typography
        color={colors.grey}
        fontSize={18}
        fontWeight="600"
        sx={{ mb: 1 }}
      >
        Bem vindo a Reazy!
      </Typography>

      <Typography color={colors.grey} fontSize={14} fontWeight="400">
        Por favor, faça o login para se conectar com a melhor plataforma de
        recrutamento
      </Typography>

      {errorMessage ? (
        <Alert severity="error" aria-live="assertive" sx={{ mb: 4, mt: 2 }}>
          {errorMessage}
        </Alert>
      ) : (
        <></>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <FormGroup sx={{ marginTop: 2 }}>
          <FormControl fullWidth>
            <TextField
              id="email"
              type="email"
              label="E-mail"
              onChange={formik.handleChange("email")}
              onBlur={formik.handleBlur("email")}
              value={formik.values.email}
              error={!!(formik.touched.email && formik.errors.email)}
              helperText={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
          </FormControl>
        </FormGroup>

        <FormGroup sx={{ marginTop: 2 }}>
          <FormControl fullWidth>
            <PasswordInput
              id="password"
              label="Senha"
              onChange={formik.handleChange("password")}
              onBlur={formik.handleBlur("password")}
              value={formik.values.password}
              error={!!(formik.touched.password && formik.errors.password)}
              helperText={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : null
              }
            />
          </FormControl>
        </FormGroup>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Link to={urls.forgotPassword}>
            <Typography
              fontSize={14}
              sx={{ mt: 1, color: theme.palette.primary.main }}
            >
              Esqueceu a Senha?
            </Typography>
          </Link>
        </Box>

        <FormGroup sx={{ marginTop: 4 }}>
          <FormControl fullWidth>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              type="submit"
              size="large"
            >
              Entrar
            </LoadingButton>
          </FormControl>
        </FormGroup>
      </form>

      <Box sx={{ marginTop: 4, textAlign: "center" }}>
        <Typography fontSize={14} color={colors.grey}>
          Novo na nossa plataforma?{" "}
          <Link
            to={urls.signup}
            style={{
              textDecoration: "none",
              color: theme.palette.primary.main,
            }}
          >
            Crie uma conta
          </Link>
        </Typography>
      </Box>
    </LoginCardContainer>
  );
}

export default Login;
