import React from "react";
import MultiInput from ".";
import { FormikProps, getIn } from "formik";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

export interface WorkExperience {
  id: number;
  title: string;
  company: string | null;
  description: string | null;
  start_date: string | null;
  end_date: string | null;
  is_current: boolean;
  _destroy: boolean;
}

interface IWorkExperience {
  formik: FormikProps<any>;
  deleteMode: "delete" | "softDelete";
  required?: boolean;
  field?: string;
  fieldError?: string;
}

const WorkExperience: React.FC<IWorkExperience> = ({
  formik,
  deleteMode,
  required = false,
  field = "work_experiences_attributes",
  fieldError = "work_experiences",
}) => {
  const renderItem = (index: number) => {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormGroup>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  label="Título"
                  size="small"
                  required
                  placeholder="Ex.: Gerente de vendas de varejo"
                  onChange={formik.handleChange(`${field}.${index}.title`)}
                  onBlur={formik.handleBlur(`${field}.${index}.title`)}
                  value={getIn(formik.values, `${field}.${index}.title`)}
                  error={
                    !!(
                      getIn(formik.touched, `${field}.${index}.title`) &&
                      getIn(formik.errors, `${fieldError}.${index}.title`)
                    )
                  }
                  helperText={
                    getIn(formik.touched, `${field}.${index}.title`) &&
                    getIn(formik.errors, `${fieldError}.${index}.title`)
                      ? getIn(formik.errors, `${fieldError}.${index}.title`)
                      : null
                  }
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: (theme) => theme.palette.common.white,
                    },
                  }}
                />
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormGroup>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  label="Nome da empresa"
                  size="small"
                  onChange={formik.handleChange(`${field}.${index}.company`)}
                  onBlur={formik.handleBlur(`${field}.${index}.company`)}
                  value={getIn(formik.values, `${field}.${index}.company`)}
                  error={
                    !!(
                      getIn(formik.touched, `${field}.${index}.company`) &&
                      getIn(formik.errors, `${fieldError}.${index}.company`)
                    )
                  }
                  helperText={
                    getIn(formik.touched, `${field}.${index}.company`) &&
                    getIn(formik.errors, `${fieldError}.${index}.company`)
                      ? getIn(formik.errors, `${fieldError}.${index}.company`)
                      : null
                  }
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: (theme) => theme.palette.common.white,
                    },
                  }}
                />
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormGroup>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  label="Descrição"
                  size="small"
                  multiline
                  rows={4}
                  onChange={formik.handleChange(
                    `${field}.${index}.description`
                  )}
                  onBlur={formik.handleBlur(`${field}.${index}.description`)}
                  value={getIn(formik.values, `${field}.${index}.description`)}
                  error={
                    !!(
                      getIn(formik.touched, `${field}.${index}.description`) &&
                      getIn(formik.errors, `${fieldError}.${index}.description`)
                    )
                  }
                  helperText={
                    getIn(formik.touched, `${field}.${index}.description`) &&
                    getIn(formik.errors, `${fieldError}.${index}.description`)
                      ? getIn(
                          formik.errors,
                          `${fieldError}.${index}.description`
                        )
                      : null
                  }
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: (theme) => theme.palette.common.white,
                    },
                  }}
                />
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControl fullWidth>
                <DatePicker
                  label="Data de início"
                  onChange={(newValue: any) => {
                    formik.setFieldValue(
                      `${field}.${index}.start_date`,
                      newValue
                    );
                  }}
                  value={getIn(formik.values, `${field}.${index}.start_date`)}
                  maxDate={
                    getIn(formik.values, `${field}.${index}.end_date`) ?? null
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                      error: !!(
                        getIn(formik.touched, `${field}.${index}.start_date`) &&
                        getIn(
                          formik.errors,
                          `${fieldError}.${index}.start_date`
                        )
                      ),
                      helperText:
                        getIn(formik.touched, `${field}.${index}.start_date`) &&
                        getIn(
                          formik.errors,
                          `${fieldError}.${index}.start_date`
                        )
                          ? getIn(
                              formik.errors,
                              `${fieldError}.${index}.start_date`
                            )
                          : null,
                      sx: {
                        "& .MuiInputBase-root": {
                          backgroundColor: (theme) =>
                            theme.palette.common.white,
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControl fullWidth>
                <DatePicker
                  label="Data de término"
                  onChange={(newValue: any) => {
                    formik.setFieldValue(
                      `${field}.${index}.end_date`,
                      newValue
                    );
                  }}
                  value={getIn(formik.values, `${field}.${index}.end_date`)}
                  minDate={
                    getIn(formik.values, `${field}.${index}.start_date`) ?? null
                  }
                  disabled={getIn(
                    formik.values,
                    `${field}.${index}.is_current`
                  )}
                  slotProps={{
                    textField: {
                      size: "small",
                      error: !!(
                        getIn(formik.touched, `${field}.${index}.end_date`) &&
                        getIn(formik.errors, `${fieldError}.${index}.end_date`)
                      ),
                      helperText:
                        getIn(formik.touched, `${field}.${index}.end_date`) &&
                        getIn(formik.errors, `${fieldError}.${index}.end_date`)
                          ? getIn(
                              formik.errors,
                              `${fieldError}.${index}.end_date`
                            )
                          : null,
                      sx: {
                        "& .MuiInputBase-root": {
                          backgroundColor: (theme) =>
                            theme.palette.common.white,
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormGroup
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getIn(
                      formik.values,
                      `${field}.${index}.is_current`
                    )}
                    onChange={(e, checked) => {
                      formik.setFieldValue(
                        `${field}.${index}.is_current`,
                        checked
                      );
                      formik.setFieldTouched(
                        `${field}.${index}.is_current`,
                        true
                      );
                      if (checked) {
                        formik.setFieldValue(
                          `${field}.${index}.end_date`,
                          null
                        );
                      }
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Trabalho atualmente neste cargo"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <MultiInput
      formik={formik}
      field={field}
      label="Experiência Profissional"
      required={required}
      initialData={{
        title: "",
        company: null,
        description: null,
        start_date: null,
        end_date: null,
        is_current: false,
      }}
      renderItem={renderItem}
      mainField="title"
      deleteMode={deleteMode}
    />
  );
};

export default WorkExperience;
