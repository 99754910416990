import React from "react";
import { useTheme, createTheme } from "@mui/material";

export default function useCompanyTheme(primaryColor: string) {
  const theme = useTheme();

  const companyTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        main: primaryColor,
      },
    },
  });

  return {
    companyTheme,
  };
}
