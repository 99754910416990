import { AxiosResponse } from "axios";
import { ResponseWithErrors } from "../../interfaces";
import requestHelper from "../../utils/requestHelper";
import queryBuilder from "../../utils/queryBuilder";

export interface CandidateNoteParams {
  id: number | null;
  note: string | null;
  candidate_id: number | null;
}

export interface CandidateNoteIndexAttributes {
  id: number;
  note: string;
  created_at: string;
  edited_at: string | null;
  user: {
    id: number;
    name: string;
  };
}

export interface CandidateNoteIndexResponse extends ResponseWithErrors {
  records: CandidateNoteIndexAttributes[];
}

export interface CandidateNoteShowResponse
  extends CandidateNoteIndexAttributes {
  candidate: {
    id: number;
    name: string;
  };
}

export interface CandidateNoteResponse
  extends CandidateNoteShowResponse,
    ResponseWithErrors {}

const index = async (
  candidateId: number
): Promise<AxiosResponse<CandidateNoteIndexResponse>> => {
  return await requestHelper
    .get(`/candidate-notes${queryBuilder({ candidate_id: candidateId })}`)
    .catch((e) => e.response);
};

const create = async (
  params: CandidateNoteParams
): Promise<AxiosResponse<CandidateNoteResponse>> => {
  return await requestHelper
    .post(`candidate-notes`, {
      candidate_note: params,
    })
    .catch((e) => e.response);
};

const update = async (
  params: CandidateNoteParams
): Promise<AxiosResponse<CandidateNoteResponse>> => {
  return await requestHelper
    .patch(`candidate-notes/${params.id}`, {
      candidate_note: params,
    })
    .catch((e) => e.response);
};

const destroy = async (
  id: string
): Promise<AxiosResponse<CandidateNoteShowResponse>> => {
  return await requestHelper
    .delete(`candidate-notes/${id}`)
    .catch((e) => e.response);
};

export { index, create, update, destroy };
