import React from "react";
import { useAppSelector } from "../app/store";
import { userSelector } from "../redux/user/userSlice";
import Backdrop from "../components/Backdrop";

const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const STRIPE_PRINCING_TABLE = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;

const ChoosePlan = () => {
  const { currentUser } = useAppSelector(userSelector);

  if (!currentUser.company) {
    return <Backdrop />;
  }

  return (
    <>
      <stripe-pricing-table
        pricing-table-id={STRIPE_PRINCING_TABLE}
        publishable-key={STRIPE_PUBLISHABLE_KEY}
        customer-email={currentUser.company.owner?.email}
      ></stripe-pricing-table>
    </>
  );
};

export default ChoosePlan;
