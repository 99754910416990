import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../utils/localStorage";
import urls from "../utils/urls";
import { useAppDispatch } from "../app/store";
import { clearState, UserAttributes } from "../redux/user/userSlice";
import { fetchUserByToken } from "../redux/user/userActions";

const PublicOnlyRoute = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUserByToken()).then((value) => {
      if (value.meta.requestStatus !== "rejected") {
        const token = getToken();
        const currentUser = value.payload as UserAttributes;
        if (token && currentUser.id) {
          if (!currentUser.company) {
            navigate(urls.adminCandidateMyApplications);
          } else {
            navigate(urls.dashboard);
          }
        }
      }
    });

    return () => {
      dispatch(clearState());
    };
  }, []);

  return <>{children}</>;
};

export default PublicOnlyRoute;
