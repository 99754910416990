import React, { useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../app/store";
import { userSelector } from "../redux/user/userSlice";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  getUsers,
  reinviteUser,
  transferOwnership,
} from "../redux/user/userActions";
import { yellow } from "@mui/material/colors";
import { Link } from "react-router-dom";
import { Edit, Mail } from "@mui/icons-material";
import urls from "../utils/urls";
import usePermission from "../hooks/usePermission";
import { texts } from "../utils/texts";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { UserReinviteResponse } from "../redux/user/userApi";
import { userStatuses } from "../utils/constants";
import TableContainer from "../components/TableContainer";

function Users() {
  const dispatch = useAppDispatch();

  const hasPermissionToUsersShow = usePermission("users.show");
  const hasPermissionToUsersUpdate = usePermission("users.update");
  const hasPermissionToUsersInvite = usePermission("users.invite");

  const [transferOwnershipDialogOpen, setTransferOwnershipDialogOpen] =
    useState<boolean>(false);
  const [idToTransferOwnership, setIdToTransferOwnership] =
    useState<GridRowId | null>(null);

  const userState = useAppSelector(userSelector);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const handleOpenTransferOwnershipDialog = (id: GridRowId) => {
    setIdToTransferOwnership(id);
    setTransferOwnershipDialogOpen(true);
  };

  const handleCloseTransferOwnershipDialog = () => {
    setTransferOwnershipDialogOpen(false);
    setIdToTransferOwnership(null);
  };

  const handleTransferOwnership = async () => {
    if (idToTransferOwnership) {
      await dispatch(transferOwnership(idToTransferOwnership.toString())).then(
        (value) => {
          if (value.meta.requestStatus !== "rejected") {
            toast.success("Propriedade transferida com sucesso");
            dispatch(getUsers());
          }
        }
      );
    }
    handleCloseTransferOwnershipDialog();
  };

  const handleReinviteUser = (id: GridRowId) => {
    dispatch(reinviteUser(id.toString())).then((value) => {
      if (value.meta.requestStatus !== "rejected") {
        toast.success("Convite reenviado com sucesso");
      } else {
        toast.error((value.payload as UserReinviteResponse).error);
      }
    });
  };

  const users = useMemo(() => {
    return userState.users.map((user) => {
      return {
        ...user,
        status: user.status ? userStatuses[user.status] : null,
      };
    });
  }, [userState.users]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      minWidth: 400,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {params.value}
          {params.row.is_owner && (
            <Tooltip title="Proprietário">
              <Icon
                baseClassName="fas"
                className="fa-crown"
                sx={{
                  color: yellow[700],
                  pr: "2px",
                  pl: 1,
                  pb: "2px",
                  fontSize: 16,
                }}
              />
            </Tooltip>
          )}
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) =>
        params.value && (
          <Chip
            label={params.value}
            color={
              params.value.toLowerCase() === "ativo" ? "success" : "warning"
            }
            size="small"
            variant="outlined"
          />
        ),
    },
    {
      field: "email",
      headerName: "E-mail",
      width: 250,
    },
    {
      field: "profile",
      headerName: "Perfil",
      width: 200,
      renderCell: (params) => {
        return <div>{params.row.profile?.name || "Personalizado"}</div>;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const actions = [];

        if (
          !row.is_owner &&
          id !== userState.currentUser.id &&
          !row.is_invited
        ) {
          actions.push(
            <Tooltip
              key={`edit-${id}`}
              title={
                hasPermissionToUsersShow || hasPermissionToUsersUpdate
                  ? "Atualizar"
                  : texts.notAuthorized
              }
            >
              <Box>
                <GridActionsCellItem
                  icon={
                    <Link to={urls.manageUser.replace(":id", id.toString())}>
                      <Edit />
                    </Link>
                  }
                  label="Edit"
                  className="textPrimary"
                  disabled={
                    !hasPermissionToUsersShow && !hasPermissionToUsersUpdate
                  }
                  // color="primary"
                />
              </Box>
            </Tooltip>
          );
        }

        if (
          userState.currentUser.is_owner &&
          !row.is_owner &&
          id !== userState.currentUser.id &&
          !row.is_invited
        ) {
          actions.push(
            <>
              <Tooltip
                key={`transfer-ownership-${id}`}
                title={"Transferir propriedade"}
              >
                <Box>
                  <GridActionsCellItem
                    icon={
                      <Icon
                        baseClassName="fas"
                        className="fa-crown"
                        sx={{
                          pr: "2px",
                          py: "2px",
                          fontSize: 18,
                        }}
                      />
                    }
                    label="Edit"
                    onClick={() => handleOpenTransferOwnershipDialog(id)}
                    className="textPrimary"
                    // color="primary"
                  />
                </Box>
              </Tooltip>
              <Dialog
                open={transferOwnershipDialogOpen}
                onClose={handleCloseTransferOwnershipDialog}
              >
                <DialogTitle>
                  Confirmar transferência da propriedade
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Tem certeza de que deseja transferir a propriedade para este
                    usuário?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    onClick={handleCloseTransferOwnershipDialog}
                  >
                    Cancelar
                  </Button>
                  <LoadingButton
                    variant="contained"
                    onClick={handleTransferOwnership}
                    // color="error"
                    loading={userState.isSaving}
                  >
                    Transferir
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            </>
          );
        }

        if (row.is_invited) {
          actions.push(
            <Tooltip
              key={`edit-${id}`}
              title={
                hasPermissionToUsersInvite
                  ? "Reenviar convite por e-mail"
                  : texts.notAuthorized
              }
            >
              <Box>
                <GridActionsCellItem
                  icon={
                    <Box>
                      <Mail />
                    </Box>
                  }
                  label="Edit"
                  className="textPrimary"
                  disabled={
                    !hasPermissionToUsersInvite || userState.isReinviting
                  }
                  onClick={() => handleReinviteUser(id)}
                  // color="primary"
                />
              </Box>
            </Tooltip>
          );
        }

        return actions;
      },
    },
  ];

  return (
    <Box>
      <Typography variant="h5">Usuários</Typography>
      <Box sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title={!hasPermissionToUsersInvite ? texts.notAuthorized : ""}>
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              size="large"
              disabled={!hasPermissionToUsersInvite}
            >
              <Link to={urls.newUser}>Convidar novo usuário</Link>
            </LoadingButton>
          </Box>
        </Tooltip>
      </Box>

      <TableContainer>
        <DataGrid
          rows={users}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
          autoHeight
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within":
              {
                outline: "none !important",
              },
          }}
          loading={userState.isFetching}
        />
      </TableContainer>
    </Box>
  );
}

export default Users;
