import React, { useEffect, useState } from "react";
import { customerPortalSession } from "../redux/stripe/stripeApi";
import statusCodes from "../utils/statusCodes";
import Backdrop from "../components/Backdrop";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Billings = () => {
  const [isFetching, setIsFetching] = useState<boolean>(true);

  useEffect(() => {
    getCustomerPortalSession();
  }, []);

  const getCustomerPortalSession = async () => {
    setIsFetching(true);
    const response = await customerPortalSession();
    if (response.status === statusCodes.ok) {
      window.location.href = response.data.url;
    } else {
      toast.error("Não foi possível ver as cobranças, tente novamente!");
      setIsFetching(false);
    }
  };

  if (isFetching) {
    return <Backdrop />;
  }

  return (
    <Box>
      <LoadingButton variant="contained" onClick={getCustomerPortalSession}>
        Ver cobranças
      </LoadingButton>
    </Box>
  );
};

export default Billings;
