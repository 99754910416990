import React, { useState } from "react";
import { Delete, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Typography, Tooltip, Collapse } from "@mui/material";
import { FormikProps, getIn } from "formik";

interface IHeader {
  initialOpen: boolean;
  initialData: object;
  formik: FormikProps<any>;
  field: string;
  index: number;
  item: { [key: string]: any };
  mainField: string;
  children: React.ReactNode;
  deleteMode: "delete" | "softDelete";
}

const Header: React.FC<IHeader> = ({
  initialOpen = false,
  initialData,
  formik,
  field,
  index,
  item,
  mainField,
  children,
  deleteMode,
}) => {
  const [open, setOpen] = useState<boolean>(initialOpen);

  const handleDelete = () => {
    if (deleteMode === "delete") {
      const updatedData = getIn(formik.values, field).filter(
        (_: any, idx: number) => idx !== index
      );
      formik.setFieldValue(field, updatedData);
    } else if (deleteMode === "softDelete") {
      Object.entries(initialData).map(([key, value]) =>
        formik.setFieldValue(`${field}.${index}.${key}`, value)
      );
      formik.setFieldValue(`${field}.${index}._destroy`, true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ cursor: "pointer" }} onClick={() => setOpen(!open)}>
          {item[mainField]}
        </Typography>

        <Box>
          <Tooltip title="Excluir">
            <Delete
              sx={{
                mr: 1,
                color: (theme) => theme.palette.grey[600],
                cursor: "pointer",
              }}
              onClick={handleDelete}
            />
          </Tooltip>
          {open ? (
            <ExpandLess
              sx={{
                color: "rgba(0, 0, 0, 0.54)",
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            />
          ) : (
            <ExpandMore
              sx={{
                color: "rgba(0, 0, 0, 0.54)",
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            />
          )}
        </Box>
      </Box>
      <Collapse in={open}>{children}</Collapse>
    </>
  );
};

export default Header;
