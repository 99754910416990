import { createSlice } from "@reduxjs/toolkit";
import {
  createCandidateNote,
  destroyCandidateNote,
  getCandidateNotes,
  updateCandidateNote,
} from "./candidateNoteActions";
import { RootState } from "../../app/store";
import {
  CandidateNoteIndexAttributes,
  CandidateNoteParams,
  CandidateNoteResponse,
  CandidateNoteShowResponse,
} from "./candidateNoteApi";
import filterErrorMessage from "../../utils/filterErrorMessage";

export interface initialState {
  initialValues: CandidateNoteParams;
  candidateNote: CandidateNoteResponse | CandidateNoteShowResponse | null;
  candidateNotes: CandidateNoteIndexAttributes[];
  isFetching: boolean;
  isSaving: boolean;
  isFinding: boolean;
  isDestroying: boolean;
  isSuccess: boolean;
  isSaveSuccess: boolean;
  isFindingSuccess: boolean;
  isDestroyingSuccess: boolean;
  isError: boolean;
  errorMessage: string | null;
}

export const initialCandidateNoteValues: CandidateNoteParams = {
  id: null,
  note: "",
  candidate_id: null,
};

export const candidateNoteSlice = createSlice({
  name: "candidateNote",
  initialState: {
    initialValues: initialCandidateNoteValues,
    candidateNote: null,
    candidateNotes: [],
    isFetching: false,
    isSaving: false,
    isFinding: false,
    isDestroying: false,
    isSuccess: false,
    isSaveSuccess: false,
    isFindingSuccess: false,
    isDestroyingSuccess: false,
    isError: false,
    errorMessage: null,
  } as initialState,

  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.isSaving = false;
      state.isFinding = false;
      state.isDestroying = false;
      state.isSuccess = false;
      state.isSaveSuccess = false;
      state.isFindingSuccess = false;
      state.isDestroyingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    clearShowState: (state) => {
      state.candidateNote = null;
      state.isFindingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    clearDestroyState: (state) => {
      state.candidateNote = null;
      state.isDestroyingSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCandidateNotes.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getCandidateNotes.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.candidateNotes = payload.records;
    });
    builder.addCase(getCandidateNotes.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.candidateNotes = [];
    });

    builder.addCase(createCandidateNote.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(createCandidateNote.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.candidateNote = payload;
    });
    builder.addCase(createCandidateNote.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as CandidateNoteResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(updateCandidateNote.pending, (state) => {
      state.isSaving = true;
      state.isSaveSuccess = false;
    });
    builder.addCase(updateCandidateNote.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.isSaveSuccess = true;
      state.candidateNote = payload;
    });
    builder.addCase(updateCandidateNote.rejected, (state, action) => {
      state.isSaving = false;
      state.isError = true;
      const payload = action.payload as CandidateNoteResponse;
      state.errorMessage = filterErrorMessage(payload?.error);
    });

    builder.addCase(destroyCandidateNote.pending, (state) => {
      state.isDestroying = true;
      state.isDestroyingSuccess = false;
    });
    builder.addCase(destroyCandidateNote.fulfilled, (state) => {
      state.isDestroying = false;
      state.isDestroyingSuccess = true;
    });
    builder.addCase(destroyCandidateNote.rejected, (state, action) => {
      state.isDestroying = false;
      state.isError = true;
      const payload = action.payload as CandidateNoteResponse;
      state.errorMessage = filterErrorMessage(
        payload?.error || payload?.error_messages?.[0]
      );
    });
  },
});

export const { clearState, clearShowState, clearDestroyState } =
  candidateNoteSlice.actions;

export const candidateNoteSelector = (state: RootState) => state.candidateNote;
