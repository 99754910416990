import { Card, CardContent, Divider, Typography } from "@mui/material";

const CardContainer = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) => {
  return (
    <Card variant="outlined" sx={{ mb: 3 }}>
      <Typography sx={{ fontWeight: "bold", px: 2, py: 1 }}>{title}</Typography>
      <Divider />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CardContainer