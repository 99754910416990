import React, { useState } from "react";
import {
  Facebook,
  GitHub,
  Instagram,
  LinkedIn,
  Phone,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { JobPipelinesAttributes } from "../../redux/job/jobApi";
import { Box, Snackbar, Tooltip, useTheme } from "@mui/material";
import tiktok_logo from "./../../assets/images/tiktok_logo.png";
import CopyToClipboard from "react-copy-to-clipboard";

const LINKEDIN_BASE_URL = "https://www.linkedin.com/in/";
const FACEBOOK_BASE_URL = "https://www.facebook.com/";
const INSTAGRAM_BASE_URL = "https://www.instagram.com/";
const TIKTOK_BASE_URL = "https://www.tiktok.com/@";
const GITHUB_BASE_URL = "https://www.github.com/";

interface ISocialLinks {
  candidate: JobPipelinesAttributes["candidates"][number];
}

const SocialLinks: React.FC<ISocialLinks> = ({ candidate }) => {
  const theme = useTheme();

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const handleCopyPhoneNumber = () => {
    setSnackbarOpen(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
      }}
    >
      {candidate?.phone_number && (
        <>
          <Box>
            <CopyToClipboard
              text={candidate.phone_number}
              onCopy={handleCopyPhoneNumber}
            >
              <Tooltip title={candidate.phone_number}>
                <Phone
                  sx={{
                    cursor: "pointer",
                    color: theme.palette.common.black,
                  }}
                  fontSize="small"
                />
              </Tooltip>
            </CopyToClipboard>

            <Snackbar
              message="Telefone Copiado"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              autoHideDuration={2000}
              onClose={() => setSnackbarOpen(false)}
              open={snackbarOpen}
            />
          </Box>
        </>
      )}
      {candidate?.linkedin && (
        <Link to={`${LINKEDIN_BASE_URL}${candidate.linkedin}`} target="_blank">
          <LinkedIn
            sx={{
              cursor: "pointer",
              color: theme.palette.common.black,
            }}
            fontSize="small"
          />
        </Link>
      )}
      {candidate?.facebook && (
        <Link to={`${FACEBOOK_BASE_URL}${candidate.facebook}`} target="_blank">
          <Facebook
            sx={{
              cursor: "pointer",
              color: theme.palette.common.black,
            }}
            fontSize="small"
          />
        </Link>
      )}
      {candidate?.instagram && (
        <Link
          to={`${INSTAGRAM_BASE_URL}${candidate.instagram}`}
          target="_blank"
        >
          <Instagram
            sx={{
              cursor: "pointer",
              color: theme.palette.common.black,
            }}
            fontSize="small"
          />
        </Link>
      )}
      {candidate?.tiktok && (
        <Link to={`${TIKTOK_BASE_URL}${candidate.tiktok}`} target="_blank">
          <img
            src={tiktok_logo}
            width="18px"
            height="18px"
            style={{
              marginTop: "1px",
              cursor: "pointer",
              color: theme.palette.common.black,
            }}
          />
        </Link>
      )}
      {candidate?.github && (
        <Link to={`${GITHUB_BASE_URL}${candidate.github}`} target="_blank">
          <GitHub
            sx={{
              cursor: "pointer",
              color: theme.palette.common.black,
            }}
            fontSize="small"
          />
        </Link>
      )}
    </Box>
  );
};

export default SocialLinks;
