import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { clearState, jobSelector } from "../../redux/job/jobSlice";
import {
  Autocomplete,
  Box,
  Container,
  FormControl,
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
  ThemeProvider,
} from "@mui/material";
import Header from "../../components/Company/Header";
import { showJob } from "../../redux/job/jobActions";
import PageNotFound from "../PageNotFound";
import Backdrop from "../../components/Backdrop";
import JobHeader from "./JobHeader";
import Dropzone from "../../components/Dropzone";
import useCandidateProfile from "../../hooks/useCandidateProfile";
import Footer from "../../components/Company/Footer";
import AvatarPicker from "../../components/AvatarPicker";
import { userSelector } from "../../redux/user/userSlice";
import useAddress from "../../hooks/useAddress";
import { maskCep, maskPhoneNumber } from "../../utils/masks";
import { stateOptions } from "../../utils/constants";
import Education from "../../components/MultiInput/Education";
import WorkExperience from "../../components/MultiInput/WorkExperience";
import { LoadingButton } from "@mui/lab";
import {
  clearState as CandidateClearState,
  candidateSelector,
} from "../../redux/candidate/candidateSlice";
import QuestionInput from "../../components/QuestionInput";
import { getIn } from "formik";
import urls from "../../utils/urls";
import success from "./../../assets/images/success.png";
import useCompanyTheme from "../../hooks/useCompanyTheme";

const ApplyJob = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { slug, job_id } = useParams<{ slug: string; job_id: string }>();

  const { candidateFormik } = useCandidateProfile();

  const {
    cities,
    loadingCities,
    stateValue,
    cityIsDisabled,
    onChangeState,
    setCitiesField,
    getAddressFromCep,
  } = useAddress(candidateFormik, null, "form.fields.address.");

  const jobState = useAppSelector(jobSelector);
  const userState = useAppSelector(userSelector);
  const candidateState = useAppSelector(candidateSelector);

  const [pageNotFound, setPageNotFound] = useState<boolean>(false);
  const [applied, setApplied] = useState<boolean>(false);

  const { companyTheme } = useCompanyTheme(
    jobState.job?.company.primary_color || theme.palette.primary.main
  );

  useEffect(() => {
    if (job_id) {
      dispatch(showJob(job_id));
    }
  }, [job_id]);

  useEffect(() => {
    return () => {
      dispatch(clearState());
      dispatch(CandidateClearState());
    };
  }, []);

  useEffect(() => {
    if (candidateState.isSaveSuccess) {
      setApplied(true);
    }
  }, [candidateState.isSaveSuccess]);

  useEffect(() => {
    if (jobState.isError) {
      setPageNotFound(true);
    }
  }, [jobState.isError]);

  const hasProfileSection = useMemo(() => {
    return (
      jobState.job?.form.fields.educations !== "off" ||
      jobState.job?.form.fields.work_experiences !== "off" ||
      jobState.job?.form.fields.summary !== "off" ||
      jobState.job?.form.fields.cover_letter !== "off"
    );
  }, [jobState.job]);

  const hasSocialMediaSection = useMemo(() => {
    return (
      jobState.job?.form.fields.linkedin !== "off" ||
      jobState.job?.form.fields.instagram !== "off" ||
      jobState.job?.form.fields.tiktok !== "off" ||
      jobState.job?.form.fields.github !== "off" ||
      jobState.job?.form.fields.facebook !== "off"
    );
  }, [jobState.job]);

  const hasQuestions = useMemo(() => {
    return jobState.job?.form.questions?.length ?? 0 > 0;
  }, [jobState.job]);

  if (pageNotFound) {
    return <PageNotFound />;
  }

  if (jobState.isFinding) {
    return <Backdrop />;
  }

  return (
    <ThemeProvider theme={companyTheme}>
      <Header
        banner={jobState.job?.company?.banner?.toString()}
        logo={jobState.job?.company?.logo?.toString()}
        name={jobState.job?.company?.name?.toString()}
        slogan={jobState.job?.company?.slogan?.toString()}
        slug={slug}
      />

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        {applied ? (
          <>
            <Box sx={{ textAlign: "center" }}>
              <Box sx={{ mb: 4 }}>
                <img src={success} width="200px" />
              </Box>

              <Typography>
                Parabéns, Sua candidatura para a vaga de{" "}
                <b>{jobState.job?.name}</b> foi recebida com sucesso.
              </Typography>
              <Typography>
                Estamos animados por você ter dado esse passo e nossa equipe
                revisará sua inscrição em breve.
              </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <Link to={urls.adminCandidateMyApplications}>
                <LoadingButton variant="contained" type="submit" size="large">
                  Ver minhas aplicações
                </LoadingButton>
              </Link>
            </Box>
          </>
        ) : (
          <>
            <JobHeader job={jobState.job} />

            {jobState.job?.form.fields.resume !== "off" && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h5">Currículo</Typography>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={12}>
                    <FormGroup>
                      <FormControl
                        error={
                          !!(
                            candidateFormik.touched.form?.fields?.resume &&
                            candidateFormik.errors.form?.fields?.resume
                          )
                        }
                      >
                        <Dropzone
                          field="form.fields.resume"
                          formik={candidateFormik}
                          supportedFiles={["application/pdf"]}
                          disableDelete
                          helperText={
                            candidateFormik.touched.form?.fields?.resume &&
                            candidateFormik.errors.form?.fields?.resume
                              ? candidateFormik.errors.form?.fields?.resume
                              : null
                          }
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Box sx={{ mt: 4 }}>
              <Typography variant="h5">Informações pessoais</Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                {jobState.job?.form.fields.avatar !== "off" && (
                  <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                    <FormGroup>
                      <FormControl
                        error={
                          !!(
                            candidateFormik.touched.form?.fields?.avatar &&
                            candidateFormik.errors.form?.fields?.avatar
                          )
                        }
                      >
                        <AvatarPicker
                          field="form.fields.avatar"
                          formik={candidateFormik}
                          label="Foto"
                          helperText={
                            candidateFormik.touched.form?.fields?.avatar &&
                            candidateFormik.errors.form?.fields?.avatar
                              ? candidateFormik.errors.form?.fields?.avatar
                              : null
                          }
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        label="Nome"
                        size="small"
                        required
                        onChange={(e) => {
                          candidateFormik.setFieldValue(
                            "form.fields.first_name",
                            e.target.value
                          );
                          candidateFormik.setFieldTouched(
                            "form.fields.first_name",
                            true
                          );
                        }}
                        onBlur={() =>
                          candidateFormik.handleBlur("form.fields.first_name")
                        }
                        value={candidateFormik.values.form?.fields?.first_name}
                        error={
                          !!(
                            candidateFormik.touched.form?.fields?.first_name &&
                            candidateFormik.errors.form?.fields?.first_name
                          )
                        }
                        helperText={
                          candidateFormik.touched.form?.fields?.first_name &&
                          candidateFormik.errors.form?.fields?.first_name
                            ? candidateFormik.errors.form?.fields?.first_name
                            : null
                        }
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        label="Sobrenome"
                        size="small"
                        required
                        onChange={(e) => {
                          candidateFormik.setFieldValue(
                            "form.fields.last_name",
                            e.target.value
                          );
                          candidateFormik.setFieldTouched(
                            "form.fields.last_name",
                            true
                          );
                        }}
                        onBlur={() =>
                          candidateFormik.handleBlur("form.fields.last_name")
                        }
                        value={candidateFormik.values.form?.fields?.last_name}
                        error={
                          !!(
                            candidateFormik.touched.form?.fields?.last_name &&
                            candidateFormik.errors.form?.fields?.last_name
                          )
                        }
                        helperText={
                          candidateFormik.touched.form?.fields?.last_name &&
                          candidateFormik.errors.form?.fields?.last_name
                            ? candidateFormik.errors.form?.fields?.last_name
                            : null
                        }
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        label="Email"
                        size="small"
                        required
                        value={userState.currentUser.email}
                        disabled
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>

                {jobState.job?.form.fields.phone_number !== "off" && (
                  <Grid item xs={12} md={6}>
                    <FormGroup>
                      <FormControl fullWidth>
                        <TextField
                          type="text"
                          label="Telefone"
                          size="small"
                          required={
                            jobState.job?.form.fields.phone_number ===
                            "required"
                          }
                          onChange={(e) => {
                            candidateFormik.setFieldValue(
                              "form.fields.phone_number",
                              maskPhoneNumber(e.target.value)
                            );
                            candidateFormik.setFieldTouched(
                              "form.fields.phone_number",
                              true
                            );
                          }}
                          onBlur={() =>
                            candidateFormik.handleBlur(
                              "form.fields.phone_number"
                            )
                          }
                          value={
                            candidateFormik.values.form?.fields?.phone_number
                          }
                          error={
                            !!(
                              candidateFormik.touched.form?.fields
                                ?.phone_number &&
                              candidateFormik.errors.form?.fields?.phone_number
                            )
                          }
                          helperText={
                            candidateFormik.touched.form?.fields
                              ?.phone_number &&
                            candidateFormik.errors.form?.fields?.phone_number
                              ? candidateFormik.errors.form?.fields
                                  ?.phone_number
                              : null
                          }
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                )}

                {jobState.job?.form.fields.address !== "off" && (
                  <>
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="CEP"
                            size="small"
                            required={
                              jobState.job?.form.fields.address === "required"
                            }
                            onChange={async (e) => {
                              const maskedCep = maskCep(e.target.value);
                              candidateFormik.setFieldValue(
                                "form.fields.address.cep",
                                maskedCep
                              );
                              candidateFormik.setFieldTouched(
                                "form.fields.address.cep",
                                true
                              );
                              if (maskedCep.length === 9) {
                                const address = await getAddressFromCep(
                                  maskedCep
                                );
                                if (address) {
                                  candidateFormik.setFieldValue(
                                    "form.fields.address.address_1",
                                    address.address_1
                                  );
                                  candidateFormik.setFieldTouched(
                                    "form.fields.address.address_1",
                                    true
                                  );
                                  candidateFormik.setFieldValue(
                                    "form.fields.address.district",
                                    address.district
                                  );
                                  candidateFormik.setFieldTouched(
                                    "form.fields.address.district",
                                    true
                                  );
                                  candidateFormik.setFieldValue(
                                    "form.fields.address.state",
                                    address.state
                                  );
                                  candidateFormik.setFieldTouched(
                                    "form.fields.address.state",
                                    true
                                  );
                                  setCitiesField(address.state);
                                  candidateFormik.setFieldValue(
                                    "form.fields.address.city",
                                    address.city
                                  );
                                  candidateFormik.setFieldTouched(
                                    "form.fields.address.city",
                                    true
                                  );
                                }
                              }
                            }}
                            onBlur={() =>
                              candidateFormik.handleBlur(
                                "form.fields.address.cep"
                              )
                            }
                            value={
                              candidateFormik.values.form?.fields?.address?.cep
                            }
                            error={
                              !!(
                                candidateFormik.touched.form?.fields?.address
                                  ?.cep &&
                                candidateFormik.errors.form?.fields?.address
                                  ?.cep
                              )
                            }
                            helperText={
                              candidateFormik.touched.form?.fields?.address
                                ?.cep &&
                              candidateFormik.errors.form?.fields?.address?.cep
                                ? candidateFormik.errors.form?.fields?.address
                                    ?.cep
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Endereço"
                            size="small"
                            required={
                              jobState.job?.form.fields.address === "required"
                            }
                            onChange={(e) => {
                              candidateFormik.setFieldValue(
                                "form.fields.address.address_1",
                                e.target.value
                              );
                              candidateFormik.setFieldTouched(
                                "form.fields.address.address_1",
                                true
                              );
                            }}
                            onBlur={() =>
                              candidateFormik.handleBlur(
                                "form.fields.address.address_1"
                              )
                            }
                            value={
                              candidateFormik.values.form?.fields?.address
                                .address_1
                            }
                            error={
                              !!(
                                candidateFormik.touched.form?.fields?.address
                                  ?.address_1 &&
                                candidateFormik.errors.form?.fields?.address
                                  ?.address_1
                              )
                            }
                            helperText={
                              candidateFormik.touched.form?.fields?.address
                                ?.address_1 &&
                              candidateFormik.errors.form?.fields?.address
                                ?.address_1
                                ? candidateFormik.errors.form?.fields?.address
                                    .address_1
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Complemento"
                            size="small"
                            onChange={(e) => {
                              candidateFormik.setFieldValue(
                                "form.fields.address.address_2",
                                e.target.value
                              );
                              candidateFormik.setFieldTouched(
                                "form.fields.address.address_2",
                                true
                              );
                            }}
                            onBlur={() =>
                              candidateFormik.handleBlur(
                                "form.fields.address.address_2"
                              )
                            }
                            value={
                              candidateFormik.values.form?.fields?.address
                                .address_2
                            }
                            error={
                              !!(
                                candidateFormik.touched.form?.fields?.address
                                  ?.address_2 &&
                                candidateFormik.errors.form?.fields?.address
                                  ?.address_2
                              )
                            }
                            helperText={
                              candidateFormik.touched.form?.fields?.address
                                ?.address_2 &&
                              candidateFormik.errors.form?.fields?.address
                                ?.address_2
                                ? candidateFormik.errors.form?.fields?.address
                                    ?.address_2
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Bairro"
                            size="small"
                            required={
                              jobState.job?.form.fields.address === "required"
                            }
                            onChange={(e) => {
                              candidateFormik.setFieldValue(
                                "form.fields.address.district",
                                e.target.value
                              );
                              candidateFormik.setFieldTouched(
                                "form.fields.address.district",
                                true
                              );
                            }}
                            onBlur={() =>
                              candidateFormik.handleBlur(
                                "form.fields.address.district"
                              )
                            }
                            value={
                              candidateFormik.values.form?.fields?.address
                                ?.district
                            }
                            error={
                              !!(
                                candidateFormik.touched.form?.fields?.address
                                  ?.district &&
                                candidateFormik.errors.form?.fields?.address
                                  ?.district
                              )
                            }
                            helperText={
                              candidateFormik.touched.form?.fields?.address
                                ?.district &&
                              candidateFormik.errors.form?.fields?.address
                                ?.district
                                ? candidateFormik.errors.form?.fields?.address
                                    ?.district
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <Autocomplete
                            disablePortal
                            id="state"
                            options={stateOptions}
                            onChange={(_, option) => onChangeState(option)}
                            value={stateValue || null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                type="text"
                                label="Estado"
                                size="small"
                                required={
                                  jobState.job?.form.fields.address ===
                                  "required"
                                }
                                onBlur={() =>
                                  candidateFormik.handleBlur(
                                    "form.fields.address.state"
                                  )
                                }
                                error={
                                  !!(
                                    candidateFormik.touched.form?.fields
                                      ?.address?.state &&
                                    candidateFormik.errors.form?.fields?.address
                                      ?.state
                                  )
                                }
                                helperText={
                                  candidateFormik.touched.form?.fields?.address
                                    ?.state &&
                                  candidateFormik.errors.form?.fields?.address
                                    ?.state
                                    ? candidateFormik.errors.form?.fields
                                        ?.address?.state
                                    : null
                                }
                              />
                            )}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <Autocomplete
                            disablePortal
                            id="city"
                            options={cities}
                            onChange={(_, option) => {
                              candidateFormik.setFieldValue(
                                "form.fields.address.city",
                                option || ""
                              );
                            }}
                            value={
                              candidateFormik.values.form.fields.address.city ||
                              null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                type="text"
                                label="Cidade"
                                size="small"
                                required={
                                  jobState.job?.form.fields.address ===
                                  "required"
                                }
                                onBlur={() =>
                                  candidateFormik.handleBlur(
                                    "form.fields.address.city"
                                  )
                                }
                                error={
                                  !!(
                                    candidateFormik.touched.form?.fields
                                      ?.address?.city &&
                                    candidateFormik.errors.form?.fields?.address
                                      ?.city
                                  )
                                }
                                helperText={
                                  candidateFormik.touched.form?.fields?.address
                                    ?.city &&
                                  candidateFormik.errors.form?.fields?.address
                                    ?.city
                                    ? candidateFormik.errors.form?.fields
                                        ?.address?.city
                                    : null
                                }
                              />
                            )}
                            loading={loadingCities}
                            disabled={cityIsDisabled}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>

            {hasProfileSection && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h5">Perfil</Typography>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {jobState.job?.form.fields.educations !== "off" && (
                    <Grid item xs={12} md={12}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <Education
                            field="form.fields.educations"
                            fieldError="form.fields.educations"
                            formik={candidateFormik}
                            deleteMode="delete"
                            required={
                              jobState.job?.form.fields.educations ===
                              "required"
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  )}
                  {jobState.job?.form.fields.work_experiences !== "off" && (
                    <Grid item xs={12} md={12}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <WorkExperience
                            field="form.fields.work_experiences"
                            fieldError="form.fields.work_experiences"
                            formik={candidateFormik}
                            deleteMode="delete"
                            required={
                              jobState.job?.form.fields.work_experiences ===
                              "required"
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  )}
                  {jobState.job?.form.fields.summary !== "off" && (
                    <Grid item xs={12} md={12}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Sumário Profissional"
                            size="small"
                            multiline
                            rows={4}
                            required={
                              jobState.job?.form.fields.summary === "required"
                            }
                            onChange={(e) => {
                              candidateFormik.setFieldValue(
                                "form.fields.summary",
                                e.target.value
                              );
                              candidateFormik.setFieldTouched(
                                "form.fields.summary",
                                true
                              );
                            }}
                            onBlur={() =>
                              candidateFormik.handleBlur("form.fields.summary")
                            }
                            value={candidateFormik.values.form?.fields?.summary}
                            error={
                              !!(
                                candidateFormik.touched.form?.fields?.summary &&
                                candidateFormik.errors.form?.fields?.summary
                              )
                            }
                            helperText={
                              candidateFormik.touched.form?.fields?.summary &&
                              candidateFormik.errors.form?.fields?.summary
                                ? candidateFormik.errors.form?.fields?.summary
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  )}

                  {jobState.job?.form.fields.cover_letter !== "off" && (
                    <Grid item xs={12} md={12}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Carta de Apresentação"
                            size="small"
                            multiline
                            rows={4}
                            required={
                              jobState.job?.form.fields.cover_letter ===
                              "required"
                            }
                            onChange={(e) => {
                              candidateFormik.setFieldValue(
                                "form.fields.cover_letter",
                                e.target.value
                              );
                              candidateFormik.setFieldTouched(
                                "form.fields.cover_letter",
                                true
                              );
                            }}
                            onBlur={() =>
                              candidateFormik.handleBlur(
                                "form.fields.cover_letter"
                              )
                            }
                            value={
                              candidateFormik.values.form?.fields?.cover_letter
                            }
                            error={
                              !!(
                                candidateFormik.touched.form?.fields
                                  ?.cover_letter &&
                                candidateFormik.errors.form?.fields
                                  ?.cover_letter
                              )
                            }
                            helperText={
                              candidateFormik.touched.form?.fields
                                ?.cover_letter &&
                              candidateFormik.errors.form?.fields?.cover_letter
                                ? candidateFormik.errors.form?.fields
                                    ?.cover_letter
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}

            {hasSocialMediaSection && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h5">Mídias sociais</Typography>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {jobState.job?.form.fields.linkedin !== "off" && (
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="LinkedIn"
                            size="small"
                            required={
                              jobState.job?.form.fields.linkedin === "required"
                            }
                            onChange={(e) => {
                              candidateFormik.setFieldValue(
                                "form.fields.linkedin",
                                e.target.value
                              );
                              candidateFormik.setFieldTouched(
                                "form.fields.linkedin",
                                true
                              );
                            }}
                            onBlur={() =>
                              candidateFormik.handleBlur("form.fields.linkedin")
                            }
                            value={
                              candidateFormik.values.form?.fields?.linkedin
                            }
                            error={
                              !!(
                                candidateFormik.touched.form?.fields
                                  ?.linkedin &&
                                candidateFormik.errors.form?.fields?.linkedin
                              )
                            }
                            helperText={
                              candidateFormik.touched.form?.fields?.linkedin &&
                              candidateFormik.errors.form?.fields?.linkedin
                                ? candidateFormik.errors.form?.fields?.linkedin
                                : null
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  https://www.linkedin.com/in/
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  )}

                  {jobState.job?.form.fields.instagram !== "off" && (
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Instagram"
                            size="small"
                            required={
                              jobState.job?.form.fields.instagram === "required"
                            }
                            onChange={(e) => {
                              candidateFormik.setFieldValue(
                                "form.fields.instagram",
                                e.target.value
                              );
                              candidateFormik.setFieldTouched(
                                "form.fields.instagram",
                                true
                              );
                            }}
                            onBlur={() =>
                              candidateFormik.handleBlur(
                                "form.fields.instagram"
                              )
                            }
                            value={
                              candidateFormik.values.form?.fields?.instagram
                            }
                            error={
                              !!(
                                candidateFormik.touched.form?.fields
                                  ?.instagram &&
                                candidateFormik.errors.form?.fields?.instagram
                              )
                            }
                            helperText={
                              candidateFormik.touched.form?.fields?.instagram &&
                              candidateFormik.errors.form?.fields?.instagram
                                ? candidateFormik.errors.form?.fields?.instagram
                                : null
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  https://www.instagram.com/
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  )}

                  {jobState.job?.form.fields.tiktok !== "off" && (
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="TikTok"
                            size="small"
                            required={
                              jobState.job?.form.fields.tiktok === "required"
                            }
                            onChange={(e) => {
                              candidateFormik.setFieldValue(
                                "form.fields.tiktok",
                                e.target.value
                              );
                              candidateFormik.setFieldTouched(
                                "form.fields.tiktok",
                                true
                              );
                            }}
                            onBlur={() =>
                              candidateFormik.handleBlur("form.fields.tiktok")
                            }
                            value={candidateFormik.values.form?.fields?.tiktok}
                            error={
                              !!(
                                candidateFormik.touched.form?.fields?.tiktok &&
                                candidateFormik.errors.form?.fields?.tiktok
                              )
                            }
                            helperText={
                              candidateFormik.touched.form?.fields?.tiktok &&
                              candidateFormik.errors.form?.fields?.tiktok
                                ? candidateFormik.errors.form?.fields?.tiktok
                                : null
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  https://www.tiktok.com/@
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  )}

                  {jobState.job?.form.fields.github !== "off" && (
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="GitHub"
                            size="small"
                            required={
                              jobState.job?.form.fields.github === "required"
                            }
                            onChange={(e) => {
                              candidateFormik.setFieldValue(
                                "form.fields.github",
                                e.target.value
                              );
                              candidateFormik.setFieldTouched(
                                "form.fields.github",
                                true
                              );
                            }}
                            onBlur={() => {
                              candidateFormik.handleBlur("form.fields.github");
                            }}
                            value={candidateFormik.values.form?.fields?.github}
                            error={
                              !!(
                                candidateFormik.touched.form?.fields?.github &&
                                candidateFormik.errors.form?.fields?.github
                              )
                            }
                            helperText={
                              candidateFormik.touched.form?.fields?.github &&
                              candidateFormik.errors.form?.fields?.github
                                ? candidateFormik.errors.form?.fields?.github
                                : null
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  https://www.github.com/
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  )}

                  {jobState.job?.form.fields.facebook !== "off" && (
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Facebook"
                            size="small"
                            required={
                              jobState.job?.form.fields.facebook === "required"
                            }
                            onChange={(e) => {
                              candidateFormik.setFieldValue(
                                "form.fields.facebook",
                                e.target.value
                              );
                              candidateFormik.setFieldTouched(
                                "form.fields.facebook",
                                true
                              );
                            }}
                            onBlur={() =>
                              candidateFormik.handleBlur("form.fields.facebook")
                            }
                            value={
                              candidateFormik.values.form?.fields?.facebook
                            }
                            error={
                              !!(
                                candidateFormik.touched.form?.fields
                                  ?.facebook &&
                                candidateFormik.errors.form?.fields?.facebook
                              )
                            }
                            helperText={
                              candidateFormik.touched.form?.fields?.facebook &&
                              candidateFormik.errors.form?.fields?.facebook
                                ? candidateFormik.errors.form?.fields?.facebook
                                : null
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  https://www.facebook.com/
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}

            {hasQuestions ? (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h5">Questões</Typography>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {jobState.job?.form.questions.map((question, index) => (
                    <Grid item xs={12} md={12}>
                      <FormGroup>
                        <FormControl
                          fullWidth
                          required={question.option === "required"}
                          error={
                            !!(
                              getIn(
                                candidateFormik.touched,
                                `form.questions.${index}.value`
                              ) &&
                              getIn(
                                candidateFormik.errors,
                                `form.questions.${index}.value`
                              )
                            )
                          }
                        >
                          <QuestionInput
                            question={question}
                            formik={candidateFormik}
                            index={index}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : null}

            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <LoadingButton
                variant="contained"
                size="large"
                loading={
                  userState.isSaving ||
                  candidateState.isSaving ||
                  candidateFormik.isSubmitting
                }
                onClick={() => candidateFormik.handleSubmit()}
              >
                Enviar inscrição
              </LoadingButton>
            </Box>
          </>
        )}
      </Container>

      <Footer />
    </ThemeProvider>
  );
};

export default ApplyJob;
