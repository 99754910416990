import { useState, useMemo, useEffect } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
  ThemeProvider,
} from "@mui/material";
import glassdoor_logo from "./../../assets/images/glassdoor_logo.png";
import user from "./../../assets/images/user.png";
import user_2 from "./../../assets/images/user_2.jpeg";
import user_3 from "./../../assets/images/user_3.jpeg";
import user_4 from "./../../assets/images/user_4.jpeg";
import user_5 from "./../../assets/images/user_5.jpeg";
import user_6 from "./../../assets/images/user_6.avif";
import {
  Facebook,
  Instagram,
  Language,
  LinkedIn,
  Search,
  Twitter,
} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import Carousel from "react-multi-carousel";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { showCompany } from "../../redux/company/companyActions";
import { clearState, companySelector } from "../../redux/company/companySlice";
import PageNotFound from "../PageNotFound";
import Backdrop from "../../components/Backdrop";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  stateOptions,
  states,
  workingModelOptions,
  workingModels,
} from "../../utils/constants";
import { State, WorkingModel } from "../../utils/types";
import { Link } from "react-router-dom";
import urls from "../../utils/urls";
import Banner from "../../components/Company/Banner";
import Footer from "../../components/Company/Footer";
import Logo from "../../components/Company/Logo";
import Header from "../../components/Company/Header";
import useCompanyTheme from "../../hooks/useCompanyTheme";

interface Row {
  id: number;
  name: string;
  state: State;
  working_model: WorkingModel;
}

const previewRows: Row[] = [
  {
    id: 1,
    name: "Exemplo Desenvolvedor Backend",
    state: "SC",
    working_model: "remote",
  },
  {
    id: 2,
    name: "Exemplo Desenvolvedor Frontend",
    state: "SC",
    working_model: "hybrid",
  },
  {
    id: 3,
    name: "Exemplo Gerente de Marketing",
    state: "RJ",
    working_model: "on_site",
  },
  {
    id: 4,
    name: "Exemplo Gerente de Vendas",
    state: "SP",
    working_model: "on_site",
  },
];

const cards = [
  {
    id: 1,
    title: "João",
    subtitle: "Gerente de Marketing",
    image: user,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam porttitor nunc ut nibh pulvinar, non tempus tortor luctus. Integer convallis posuere faucibus. Nullam libero est, sodales ut neque eu, iaculis placerat sem.",
  },
  {
    id: 2,
    title: "Maria",
    subtitle: "Gerente de Vendas",
    image: user_2,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam porttitor nunc ut nibh pulvinar, non tempus tortor luctus. Integer convallis posuere faucibus.",
  },
  {
    id: 3,
    title: "Marcos",
    subtitle: "Gestor Financeiro",
    image: user_3,
    description:
      "Integer convallis posuere faucibus. Nullam libero est, sodales ut neque eu, iaculis placerat sem. Pellentesque pharetra justo et lacus vulputate, sed tincidunt sapien sodales.",
  },
  {
    id: 4,
    title: "Carla",
    subtitle: "Recrutadora de talentos",
    image: user_4,
    description:
      "Consectetur adipiscing elit. Aliquam porttitor nunc ut nibh pulvinar iaculis placerat sem. Pellentesque pharetra justo et lacus vulputate, sed tincidunt sapien sodales.",
  },
  {
    id: 5,
    title: "Ricardo",
    subtitle: "Desenvolvedor Frontend",
    image: user_5,
    description:
      "Dolor sit amet, consectetur adipiscing elit. Nullam libero est, sodales ut neque eu, iaculis placerat sem. Sed tincidunt sapien sodales.",
  },
  {
    id: 6,
    title: "Letícia",
    subtitle: "Desenvolvedora Backend",
    image: user_6,
    description:
      "Aliquam porttitor nunc ut nibh pulvinar, non tempus tortor luctus. Integer convallis posuere faucibus.",
  },
];

const LINKEDIN_BASE_URL = "https://www.linkedin.com/company/";
const FACEBOOK_BASE_URL = "https://www.facebook.com/";
const INSTAGRAM_BASE_URL = "https://www.instagram.com/";
const TWITTER_BASE_URL = "https://www.twitter.com/";
const GLASSDOR_BASE_URL = "https://www.glassdoor.com/";

export interface CompanyData {
  slug: string;
  website: string | null;
  linkedin: string | null;
  facebook: string | null;
  instagram: string | null;
  twitter: string | null;
  glassdoor: string | null;
  primary_color: string;
  name: string | null;
  slogan: string | null;
  description_title: string | null;
  description: string | null;
  logo: string | ArrayBuffer | null;
  banner: string | ArrayBuffer | null;
}

interface ICompany {
  isPreview?: boolean;
  previewData?: CompanyData;
}

const Company: React.FC<ICompany> = ({ isPreview, previewData }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { width } = useWindowDimensions();

  const { slug } = useParams<{ slug: string }>();

  const companyState = useAppSelector(companySelector);

  const [data, setData] = useState<CompanyData | null>(previewData || null);
  const [query, setQuery] = useState<string>("");
  const [state, setState] = useState<{
    id: string;
    label: string;
  } | null>({ id: "all", label: "Todos" });
  const [workingModel, setWorkingModel] = useState<{
    id: string;
    label: string;
  } | null>({ id: "all", label: "Todos" });
  const [pageNotFound, setPageNotFound] = useState<boolean>(false);

  const { companyTheme } = useCompanyTheme(
    data?.primary_color || theme.palette.primary.main
  );

  useEffect(() => {
    if (slug && !isPreview) {
      dispatch(showCompany(slug));
    }
  }, [slug, isPreview]);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (companyState.isFindingSuccess && !isPreview) {
      setData(companyState.company);
    }
  }, [companyState.isFindingSuccess, isPreview]);

  useEffect(() => {
    if (companyState.isError && !isPreview) {
      setPageNotFound(true);
    }
  }, [companyState.isError, isPreview]);

  useEffect(() => {
    if (previewData) {
      setData(previewData);
    }
  }, [previewData]);

  useEffect(() => {
    setTimeout(() => {
      const carouselCardItems = Array.from(
        document.querySelectorAll("#carousel li > div")
      ) as HTMLElement[];

      let largestHeight = 0;
      carouselCardItems.forEach((item: HTMLElement) => {
        item.style.height = "auto";
        if (item.offsetHeight > largestHeight) {
          largestHeight = item.offsetHeight;
        }
      });

      carouselCardItems.forEach((item: HTMLElement) => {
        item.style.height = `${largestHeight}px`;
      });
    }, 1);
  }, [width]);

  const responsive = {
    xl: {
      breakpoint: { max: 8000, min: 1024 },
      items: 4,
    },
    lg: {
      breakpoint: { max: 1024, min: 840 },
      items: 3,
    },
    md: {
      breakpoint: { max: 840, min: 599 },
      items: 2,
    },
    sm: {
      breakpoint: { max: 599, min: 0 },
      items: 1,
    },
  };

  const filteredJobs: Row[] = useMemo(() => {
    if (!isPreview && !companyState.company) {
      return [];
    }

    let newRows = isPreview
      ? [...previewRows]
      : (companyState.company?.jobs as Row[]);
    newRows = newRows.filter((row) => {
      return (
        query === "" || row.name.toLowerCase().includes(query.toLowerCase())
      );
    });
    newRows = newRows.filter((row) => {
      return !state || state?.id === "all" || row.state == state?.id;
    });
    newRows = newRows.filter((row) => {
      return (
        !workingModel ||
        workingModel?.id === "all" ||
        row.working_model == workingModel?.id
      );
    });
    return newRows;
  }, [query, state, workingModel, companyState.company]);

  const jobLink = (id: number) => {
    if (isPreview) {
      return "";
    }

    return urls.companyJobPage
      .replace(":slug", companyState.company?.slug || "")
      .replace(":job_id", id.toString());
  };

  const cardItem = (item: {
    id: number;
    title: string | null;
    subtitle: string | null;
    image: string | null;
    description: string | null;
  }) => {
    return (
      <Box
        sx={{
          marginTop: 7,
          marginBottom: 1,
          marginLeft: 1,
          marginRight: 1,
          position: "relative",
          height: "auto",
        }}
        key={item.id}
      >
        {item.image && (
          <Box
            width="100px"
            height="100px"
            sx={{
              borderRadius: "50px",
              position: "absolute",
              top: "-50px",
              left: "calc(50% - 50px)",
            }}
          >
            <img
              src={item.image}
              width="100%"
              height="100%"
              style={{
                objectFit: "cover",
                objectPosition: "center center",
                borderRadius: "50px",
                border: `1px solid ${companyTheme.palette.grey[200]}`,
              }}
            />
          </Box>
        )}
        <Card sx={{ height: "100%" }}>
          <CardContent sx={{ textAlign: "center" }}>
            {item.title && (
              <Typography
                sx={{
                  fontWeight: "bold",
                  marginTop: item.image ? 5 : 0,
                  height: "100%",
                }}
              >
                {item.title}
              </Typography>
            )}
            {item.subtitle && (
              <Typography sx={{ fontSize: 12 }} color="grey">
                {item.subtitle}
              </Typography>
            )}
            {item.description && (
              <Typography sx={{ fontSize: 14, marginTop: 2 }}>
                {item.description}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  if (pageNotFound) {
    return <PageNotFound />;
  }

  if (companyState.isFinding) {
    return <Backdrop />;
  }

  return (
    <ThemeProvider theme={companyTheme}>
      <Header
        banner={data?.banner?.toString()}
        logo={data?.logo?.toString()}
        name={data?.name?.toString()}
        slogan={data?.slogan?.toString()}
        slug={slug}
      />

      {(data?.website ||
        data?.linkedin ||
        data?.facebook ||
        data?.instagram ||
        data?.twitter ||
        data?.glassdoor) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            marginTop: 2,
            gap: 2,
            px: 2,
          }}
        >
          {data?.website && (
            <Link to={data.website} target="_blank">
              <Language
                sx={{
                  cursor: "pointer",
                  color: companyTheme.palette.common.black,
                }}
              />
            </Link>
          )}
          {data?.linkedin && (
            <Link to={`${LINKEDIN_BASE_URL}${data.linkedin}`} target="_blank">
              <LinkedIn
                sx={{
                  cursor: "pointer",
                  color: companyTheme.palette.common.black,
                }}
              />
            </Link>
          )}
          {data?.facebook && (
            <Link to={`${FACEBOOK_BASE_URL}${data.facebook}`} target="_blank">
              <Facebook
                sx={{
                  cursor: "pointer",
                  color: companyTheme.palette.common.black,
                }}
              />
            </Link>
          )}
          {data?.instagram && (
            <Link to={`${INSTAGRAM_BASE_URL}${data.instagram}`} target="_blank">
              <Instagram
                sx={{
                  cursor: "pointer",
                  color: companyTheme.palette.common.black,
                }}
              />
            </Link>
          )}
          {data?.twitter && (
            <Link to={`${TWITTER_BASE_URL}${data.twitter}`} target="_blank">
              <Twitter
                sx={{
                  cursor: "pointer",
                  color: companyTheme.palette.common.black,
                }}
              />
            </Link>
          )}
          {data?.glassdoor && (
            <Link to={`${GLASSDOR_BASE_URL}${data.glassdoor}`} target="_blank">
              <img
                src={glassdoor_logo}
                width="19px"
                height="19px"
                style={{
                  marginTop: "2px",
                  cursor: "pointer",
                  color: companyTheme.palette.common.black,
                }}
              />
            </Link>
          )}
        </Box>
      )}

      <Box sx={{ marginTop: 4 }}>
        <Container maxWidth="lg">
          <Typography variant="h5">Vagas</Typography>
          <Box sx={{ marginTop: 2, gap: 2, display: "flex", flexWrap: "wrap" }}>
            <FormControl
              sx={{
                minWidth: { xs: "100%", sm: isPreview ? "100%" : "500px" },
              }}
            >
              <OutlinedInput
                id="query"
                type={"text"}
                placeholder="Pesquise pela vaga"
                startAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="start">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                }
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </FormControl>

            <FormControl
              sx={{ minWidth: "200px", width: { xs: "100%", sm: "auto" } }}
            >
              <Autocomplete
                disablePortal
                id="state"
                options={[{ id: "all", label: "Todos" }, ...stateOptions]}
                value={state}
                onChange={(_, option) => setState(option)}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} type="text" label="Local" />
                )}
              />
            </FormControl>

            <FormControl
              sx={{ minWidth: "200px", width: { xs: "100%", sm: "auto" } }}
            >
              <Autocomplete
                disablePortal
                id="working_model"
                options={[
                  { id: "all", label: "Todos" },
                  ...workingModelOptions,
                ]}
                value={workingModel}
                onChange={(_, option) => setWorkingModel(option)}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} type="text" label="Modelo" />
                )}
              />
            </FormControl>
          </Box>
          <TableContainer sx={{ marginTop: 2 }}>
            <Table sx={{ display: { xs: "none", sm: "table" } }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>Vaga</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>Local</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>Modelo</Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ maxWidth: "90px" }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredJobs.length > 0 ? (
                  filteredJobs.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Link
                          to={jobLink(row.id)}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Typography sx={{ cursor: "pointer" }}>
                            {row.name}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell align="right">
                        <Typography>
                          {row.state ? states[row.state] : ""}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography>
                          {row.working_model
                            ? workingModels[row.working_model]
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell align="right" sx={{ maxWidth: "100px" }}>
                        <Link to={jobLink(row.id)}>
                          <Button variant="contained">Ver Vaga</Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell colSpan={4} align="center">
                      <Typography sx={{ marginTop: 2 }}>
                        Nenhuma vaga encontrada
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Table sx={{ display: { xs: "table", sm: "none" } }}>
              <TableBody>
                {filteredJobs.length > 0 &&
                  filteredJobs.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Link
                          to={jobLink(row.id)}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Typography
                            sx={{
                              cursor: "pointer",
                              fontSize: 17,
                              fontWeight: "bold",
                              color: companyTheme.palette.primary.main,
                            }}
                          >
                            {row.name}
                          </Typography>
                        </Link>
                        <Typography>
                          {row.state ? `${states[row.state]} - ` : ""}
                          {row.working_model
                            ? workingModels[row.working_model]
                            : ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Box>

      {(data?.description || data?.description_title) && (
        <Box marginTop={4}>
          <Container maxWidth="lg">
            {data?.description_title && (
              <Typography variant="h5">{data.description_title}</Typography>
            )}
            {data?.description && (
              <Box marginTop={2}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: data.description,
                  }}
                ></Typography>
              </Box>
            )}
          </Container>
        </Box>
      )}

      {/* {cards.length > 0 && (
        <Box marginTop={4}>
          <Container maxWidth="lg">
            <Typography variant="h5">
              Por que nossos clientes amam a Reazy
            </Typography>

            <Box id="carousel" sx={{ marginTop: 2 }}>
              <Carousel
                responsive={responsive}
                arrows={false}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
              >
                {cards.map((item) => (
                  <>{cardItem(item)}</>
                ))}
              </Carousel>
            </Box>
          </Container>
        </Box>
      )} */}

      <Footer />
    </ThemeProvider>
  );
};

export default Company;
