import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../app/store";
import { destroyBenefit, getBenefits } from "../redux/benefit/benefitActions";
import { benefitSelector } from "../redux/benefit/benefitSlice";
import { toast } from "react-toastify";
import { Edit, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import urls from "../utils/urls";
import usePermission from "../hooks/usePermission";
import { texts } from "../utils/texts";
import TableContainer from "../components/TableContainer";

function Benefits() {
  const dispatch = useAppDispatch();

  const hasPermissionToBenefitsShow = usePermission("benefits.show");
  const hasPermissionToBenefitsCreate = usePermission("benefits.create");
  const hasPermissionToBenefitsUpdate = usePermission("benefits.update");
  const hasPermissionToBenefitsDestroy = usePermission("benefits.destroy");

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<GridRowId | null>(null);

  const benefitState = useAppSelector(benefitSelector);

  useEffect(() => {
    dispatch(getBenefits());
  }, []);

  const handleOpenDeleteDialog = (id: GridRowId) => {
    setIdToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setIdToDelete(null);
  };

  const handleDelete = async () => {
    if (idToDelete) {
      await dispatch(destroyBenefit(idToDelete.toString())).then((value) => {
        if (value.meta.requestStatus !== "rejected") {
          toast.success("Benefício excluído com sucesso");
          dispatch(getBenefits());
        }
      });
    }
    handleCloseDeleteDialog();
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      minWidth: 400,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <Tooltip
            key={`edit-${id}`}
            title={
              hasPermissionToBenefitsShow || hasPermissionToBenefitsUpdate
                ? "Atualizar"
                : texts.notAuthorized
            }
          >
            <Box>
              <GridActionsCellItem
                icon={
                  <Link to={urls.manageBenefit.replace(":id", id.toString())}>
                    <Edit />
                  </Link>
                }
                label="Edit"
                className="textPrimary"
                disabled={
                  !hasPermissionToBenefitsShow && !hasPermissionToBenefitsUpdate
                }
                // color="primary"
              />
            </Box>
          </Tooltip>,
          <Box key={`delete-${id}`}>
            <Tooltip
              title={
                hasPermissionToBenefitsDestroy ? "Excluir" : texts.notAuthorized
              }
            >
              <Box>
                <GridActionsCellItem
                  icon={<Delete />}
                  label="Delete"
                  onClick={() => handleOpenDeleteDialog(id)}
                  disabled={!hasPermissionToBenefitsDestroy}
                  // color="error"
                />
              </Box>
            </Tooltip>
            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
              <DialogTitle>Confirmar exclusão</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem certeza de que deseja excluir este benefício?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={handleCloseDeleteDialog}>
                  Cancelar
                </Button>
                <LoadingButton
                  variant="contained"
                  onClick={handleDelete}
                  color="error"
                  loading={benefitState.isDestroying}
                >
                  Excluir
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </Box>,
        ];
      },
    },
  ];

  return (
    <Box>
      <Typography variant="h5">Benefícios</Typography>
      <Box sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "flex-end" }}>
        <Tooltip
          title={!hasPermissionToBenefitsCreate ? texts.notAuthorized : ""}
        >
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              size="large"
              disabled={!hasPermissionToBenefitsCreate}
            >
              <Link to={urls.newBenefit}>Adicionar novo benefício</Link>
            </LoadingButton>
          </Box>
        </Tooltip>
      </Box>

      <TableContainer>
        <DataGrid
          rows={benefitState.benefits}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
          autoHeight
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within":
              {
                outline: "none !important",
              },
          }}
          loading={benefitState.isFetching}
        />
      </TableContainer>
    </Box>
  );
}

export default Benefits;
