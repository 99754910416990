import React from "react";
import { Box, colors, Typography } from "@mui/material";

interface IChip {
  label: string;
  color: string;
}

const Chip: React.FC<IChip> = ({ label, color }) => {
  return (
    <Box
      sx={{
        height: 24,
        borderRadius: 4,
        backgroundColor: color,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          px: 2,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          color: colors.common.white,
          fontSize: "0.8125rem",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default Chip;
