import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../app/store";
import {
  destroyDepartment,
  getDepartments,
} from "../redux/department/departmentActions";
import { departmentSelector } from "../redux/department/departmentSlice";
import { toast } from "react-toastify";
import { Edit, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import urls from "../utils/urls";
import usePermission from "../hooks/usePermission";
import { texts } from "../utils/texts";
import TableContainer from "../components/TableContainer";

function Departments() {
  const dispatch = useAppDispatch();

  const hasPermissionToDepartmentsShow = usePermission("departments.show");
  const hasPermissionToDepartmentsCreate = usePermission("departments.create");
  const hasPermissionToDepartmentsUpdate = usePermission("departments.update");
  const hasPermissionToDepartmentsDestroy = usePermission(
    "departments.destroy"
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<GridRowId | null>(null);

  const departmentState = useAppSelector(departmentSelector);

  useEffect(() => {
    dispatch(getDepartments());
  }, []);

  const handleOpenDeleteDialog = (id: GridRowId) => {
    setIdToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setIdToDelete(null);
  };

  const handleDelete = async () => {
    if (idToDelete) {
      await dispatch(destroyDepartment(idToDelete.toString())).then((value) => {
        if (value.meta.requestStatus !== "rejected") {
          toast.success("Departamento excluído com sucesso");
          dispatch(getDepartments());
        }
      });
    }
    handleCloseDeleteDialog();
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      minWidth: 400,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <Tooltip
            key={`edit-${id}`}
            title={
              hasPermissionToDepartmentsShow || hasPermissionToDepartmentsUpdate
                ? "Atualizar"
                : texts.notAuthorized
            }
          >
            <Box>
              <GridActionsCellItem
                icon={
                  <Link
                    to={urls.manageDepartment.replace(":id", id.toString())}
                  >
                    <Edit />
                  </Link>
                }
                label="Edit"
                className="textPrimary"
                disabled={
                  !hasPermissionToDepartmentsShow &&
                  !hasPermissionToDepartmentsUpdate
                }
                // color="primary"
              />
            </Box>
          </Tooltip>,
          <Box key={`delete-${id}`}>
            <Tooltip
              title={
                hasPermissionToDepartmentsDestroy
                  ? "Excluir"
                  : texts.notAuthorized
              }
            >
              <Box>
                <GridActionsCellItem
                  icon={<Delete />}
                  label="Delete"
                  onClick={() => handleOpenDeleteDialog(id)}
                  disabled={!hasPermissionToDepartmentsDestroy}
                  // color="error"
                />
              </Box>
            </Tooltip>
            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
              <DialogTitle>Confirmar exclusão</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem certeza de que deseja excluir este benefício?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={handleCloseDeleteDialog}>
                  Cancelar
                </Button>
                <LoadingButton
                  variant="contained"
                  onClick={handleDelete}
                  color="error"
                  loading={departmentState.isDestroying}
                >
                  Excluir
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </Box>,
        ];
      },
    },
  ];

  return (
    <Box>
      <Typography variant="h5">Departamentos</Typography>
      <Box sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "flex-end" }}>
        <Tooltip
          title={!hasPermissionToDepartmentsCreate ? texts.notAuthorized : ""}
        >
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              size="large"
              disabled={!hasPermissionToDepartmentsCreate}
            >
              <Link to={urls.newDepartment}>Adicionar novo departamento</Link>
            </LoadingButton>
          </Box>
        </Tooltip>
      </Box>

      <TableContainer>
        <DataGrid
          rows={departmentState.departments}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
          autoHeight
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within":
              {
                outline: "none !important",
              },
          }}
          loading={departmentState.isFetching}
        />
      </TableContainer>
    </Box>
  );
}

export default Departments;
