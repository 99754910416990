import React, { useRef } from "react";
import { Box } from "@mui/material";
import useViewportHeight from "../hooks/useViewportHeight";

interface ITableContainer {
  children: React.ReactNode;
}

const TableContainer: React.FC<ITableContainer> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { offsetTop } = useViewportHeight(containerRef);

  return (
    <Box
      ref={containerRef}
      sx={{
        height: `calc(100vh - ${offsetTop + 24}px)`,
        width: "100%",
        minWidth: 0,
      }}
    >
      {children}
    </Box>
  );
};

export default TableContainer;
