import React from "react";
import MultiInput from ".";
import { FormikProps, getIn } from "formik";
import { Box, FormControl, FormGroup, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

export interface Education {
  id: number;
  school: string;
  degree: string | null;
  field_of_study: string | null;
  start_date: string | null;
  end_date: string | null;
  _destroy: boolean;
}

interface IEducation {
  formik: FormikProps<any>;
  deleteMode: "delete" | "softDelete";
  required?: boolean;
  field?: string;
  fieldError?: string;
}

const Education: React.FC<IEducation> = ({
  formik,
  deleteMode,
  required = false,
  field = "educations_attributes",
  fieldError = "educations",
}) => {
  const renderItem = (index: number) => {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormGroup>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  label="Instituição de ensino"
                  size="small"
                  required
                  placeholder="Ex.: Fundação Getúlio Vargas"
                  onChange={formik.handleChange(`${field}.${index}.school`)}
                  onBlur={formik.handleBlur(`${field}.${index}.school`)}
                  value={getIn(formik.values, `${field}.${index}.school`)}
                  error={
                    !!(
                      getIn(formik.touched, `${field}.${index}.school`) &&
                      getIn(formik.errors, `${fieldError}.${index}.school`)
                    )
                  }
                  helperText={
                    getIn(formik.touched, `${field}.${index}.school`) &&
                    getIn(formik.errors, `${fieldError}.${index}.school`)
                      ? getIn(formik.errors, `${fieldError}.${index}.school`)
                      : null
                  }
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: (theme) => theme.palette.common.white,
                    },
                  }}
                />
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormGroup>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  label="Diploma"
                  size="small"
                  placeholder="Ex.: Bacharelado"
                  onChange={formik.handleChange(`${field}.${index}.degree`)}
                  onBlur={formik.handleBlur(`${field}.${index}.degree`)}
                  value={getIn(formik.values, `${field}.${index}.degree`)}
                  error={
                    !!(
                      getIn(formik.touched, `${field}.${index}.degree`) &&
                      getIn(formik.errors, `${fieldError}.${index}.degree`)
                    )
                  }
                  helperText={
                    getIn(formik.touched, `${field}.${index}.degree`) &&
                    getIn(formik.errors, `${fieldError}.${index}.degree`)
                      ? getIn(formik.errors, `${fieldError}.${index}.degree`)
                      : null
                  }
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: (theme) => theme.palette.common.white,
                    },
                  }}
                />
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormGroup>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  label="Área de estudo"
                  size="small"
                  placeholder="Ex.: Administração"
                  onChange={formik.handleChange(
                    `${field}.${index}.field_of_study`
                  )}
                  onBlur={formik.handleBlur(`${field}.${index}.field_of_study`)}
                  value={getIn(
                    formik.values,
                    `${field}.${index}.field_of_study`
                  )}
                  error={
                    !!(
                      getIn(
                        formik.touched,
                        `${field}.${index}.field_of_study`
                      ) &&
                      getIn(
                        formik.errors,
                        `${fieldError}.${index}.field_of_study`
                      )
                    )
                  }
                  helperText={
                    getIn(formik.touched, `${field}.${index}.field_of_study`) &&
                    getIn(
                      formik.errors,
                      `${fieldError}.${index}.field_of_study`
                    )
                      ? getIn(
                          formik.errors,
                          `${fieldError}.${index}.field_of_study`
                        )
                      : null
                  }
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: (theme) => theme.palette.common.white,
                    },
                  }}
                />
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControl fullWidth>
                <DatePicker
                  label="Data de início"
                  onChange={(newValue: any) => {
                    formik.setFieldValue(
                      `${field}.${index}.start_date`,
                      newValue
                    );
                  }}
                  value={getIn(formik.values, `${field}.${index}.start_date`)}
                  maxDate={
                    getIn(formik.values, `${field}.${index}.end_date`) ?? null
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                      error: !!(
                        getIn(formik.touched, `${field}.${index}.start_date`) &&
                        getIn(
                          formik.errors,
                          `${fieldError}.${index}.start_date`
                        )
                      ),
                      helperText:
                        getIn(formik.touched, `${field}.${index}.start_date`) &&
                        getIn(
                          formik.errors,
                          `${fieldError}.${index}.start_date`
                        )
                          ? getIn(
                              formik.errors,
                              `${fieldError}.${index}.start_date`
                            )
                          : null,
                      sx: {
                        "& .MuiInputBase-root": {
                          backgroundColor: (theme) =>
                            theme.palette.common.white,
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControl fullWidth>
                <DatePicker
                  label="Data de término"
                  onChange={(newValue: any) => {
                    formik.setFieldValue(
                      `${field}.${index}.end_date`,
                      newValue
                    );
                  }}
                  value={getIn(formik.values, `${field}.${index}.end_date`)}
                  minDate={
                    getIn(formik.values, `${field}.${index}.start_date`) ?? null
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                      error: !!(
                        getIn(formik.touched, `${field}.${index}.end_date`) &&
                        getIn(formik.errors, `${fieldError}.${index}.end_date`)
                      ),
                      helperText:
                        getIn(formik.touched, `${field}.${index}.end_date`) &&
                        getIn(formik.errors, `${fieldError}.${index}.end_date`)
                          ? getIn(
                              formik.errors,
                              `${fieldError}.${index}.end_date`
                            )
                          : null,
                      sx: {
                        "& .MuiInputBase-root": {
                          backgroundColor: (theme) =>
                            theme.palette.common.white,
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <MultiInput
      formik={formik}
      field={field}
      label="Educação"
      required={required}
      initialData={{
        school: "",
        degree: null,
        field_of_study: null,
        start_date: null,
        end_date: null,
      }}
      renderItem={renderItem}
      mainField="school"
      deleteMode={deleteMode}
    />
  );
};

export default Education;
