import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { GridActionsCellItem, GridColDef, GridRowId } from "@mui/x-data-grid";
import { destroyJob, getJobs } from "../redux/job/jobActions";
import { useAppDispatch, useAppSelector } from "../app/store";
import { userSelector } from "../redux/user/userSlice";
import { jobSelector } from "../redux/job/jobSlice";
import { Link } from "react-router-dom";
import { Delete, Edit, Group, MoreVert, Visibility } from "@mui/icons-material";
import { toast } from "react-toastify";
import { workingModels, workingTypes } from "../utils/constants";
import urls from "../utils/urls";
import usePermission from "../hooks/usePermission";
import { texts } from "../utils/texts";
import TableContainer from "../components/TableContainer";
import useJobStatus from "../hooks/useJobStatus";
import Chip from "../components/Chip";
import Table from "../components/Table";
import MenuDropdown, {
  IMenuDropdownRef,
  MenuItemDropdown,
} from "../components/MenuDropdown";

function Jobs() {
  const dispatch = useAppDispatch();

  const hasPermissionToJobsShow = usePermission("jobs.show");
  const hasPermissionToJobsCreate = usePermission("jobs.create");
  const hasPermissionToJobsUpdate = usePermission("jobs.update");
  const hasPermissionToJobsDestroy = usePermission("jobs.destroy");

  const actionRefs = useRef<{ [key: GridRowId]: IMenuDropdownRef | null }>({});

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<GridRowId | null>(null);

  const userState = useAppSelector(userSelector);
  const jobState = useAppSelector(jobSelector);

  useEffect(() => {
    if (userState.currentUser.company?.id) {
      dispatch(getJobs(userState.currentUser.company.id));
    }
  }, [userState.currentUser.company?.id]);

  const handleOpenDeleteDialog = (id: GridRowId) => {
    setIdToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setIdToDelete(null);
  };

  const handleDelete = async () => {
    if (idToDelete) {
      await dispatch(destroyJob(idToDelete.toString())).then((value) => {
        if (value.meta.requestStatus !== "rejected") {
          toast.success("Vaga excluída com sucesso");
          if (userState.currentUser.company) {
            dispatch(getJobs(userState.currentUser.company.id));
          }
        }
      });
    }
    handleCloseDeleteDialog();
  };

  const jobs = useMemo(() => {
    return jobState.jobs.map((job) => {
      return {
        ...job,
        working_type: job.working_type ? workingTypes[job.working_type] : "",
        working_model: job.working_model
          ? workingModels[job.working_model]
          : "",
      };
    });
  }, [jobState.jobs]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Título da vaga",
      flex: 1,
      minWidth: 400,
    },
    {
      field: "working_type",
      headerName: "Regime de contratação",
      width: 200,
    },
    {
      field: "working_model",
      headerName: "Modelo de Trabalho",
      width: 200,
    },
    {
      field: "candidates_count",
      headerName: "Candidatos",
      width: 120,
      renderCell: (params) => {
        return params.row.status !== "draft" ? params.value : "";
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        const jobStatus = useJobStatus(params.value);

        return <Chip label={jobStatus.name} color={jobStatus.color} />;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      width: 80,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const actions = [
          <GridActionsCellItem
            icon={<MoreVert />}
            label="Show"
            className="textPrimary"
            onClick={(e) => actionRefs?.current[id]?.open(e)}
          />,
          <MenuDropdown
            id={`jobs-action-${id}`}
            ref={(el) => (actionRefs.current[id] = el)}
          >
            <Tooltip
              title={
                !(hasPermissionToJobsShow || hasPermissionToJobsUpdate)
                  ? texts.notAuthorized
                  : null
              }
            >
              <Box>
                <MenuItemDropdown
                  component="a"
                  href={urls.manageJob.replace(":id", id.toString())}
                  disabled={
                    !(hasPermissionToJobsShow || hasPermissionToJobsUpdate)
                  }
                >
                  <ListItemIcon>
                    <Edit fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Atualizar</ListItemText>
                </MenuItemDropdown>
              </Box>
            </Tooltip>
            {row.status !== "draft" ? (
              <Tooltip
                title={!hasPermissionToJobsShow ? texts.notAuthorized : null}
              >
                <Box>
                  <MenuItemDropdown
                    component="a"
                    href={urls.candidatesJob.replace(":id", id.toString())}
                    disabled={!hasPermissionToJobsShow}
                  >
                    <ListItemIcon>
                      <Group fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Ver candidatos</ListItemText>
                  </MenuItemDropdown>
                </Box>
              </Tooltip>
            ) : null}
            {row.status === "published" ? (
              <MenuItemDropdown
                component="a"
                href={urls.companyJobPage
                  .replace(":slug", userState.currentUser.company?.slug || "")
                  .replace(":job_id", id.toString())}
                target="_blank"
              >
                <ListItemIcon>
                  <Visibility fontSize="small" />
                </ListItemIcon>
                <ListItemText>Ver no site</ListItemText>
              </MenuItemDropdown>
            ) : null}
            <Tooltip
              title={!hasPermissionToJobsDestroy ? texts.notAuthorized : null}
            >
              <Box>
                <MenuItemDropdown
                  onClick={() => handleOpenDeleteDialog(id)}
                  disabled={!hasPermissionToJobsDestroy}
                >
                  <ListItemIcon>
                    <Delete fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Excluir</ListItemText>
                </MenuItemDropdown>
              </Box>
            </Tooltip>
            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
              <DialogTitle>Confirmar exclusão</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem certeza de que deseja excluir esta vaga?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={handleCloseDeleteDialog}>
                  Cancelar
                </Button>
                <LoadingButton
                  variant="contained"
                  onClick={handleDelete}
                  color="error"
                  loading={jobState.isDestroying}
                >
                  Excluir
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </MenuDropdown>,
        ];

        return actions;
      },
    },
  ];

  return (
    <Box>
      <Typography variant="h5">Vagas</Typography>
      <Box sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title={!hasPermissionToJobsCreate ? texts.notAuthorized : ""}>
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              size="large"
              disabled={!hasPermissionToJobsCreate}
            >
              <Link to={urls.newJob}>Adicionar nova vaga</Link>
            </LoadingButton>
          </Box>
        </Tooltip>
      </Box>

      <TableContainer>
        <Table rows={jobs} columns={columns} loading={jobState.isFetching} />
      </TableContainer>
    </Box>
  );
}

export default Jobs;
