import queryString from 'qs'

interface Params {
  [field: string]: any
}

export default (params: Params) => {
  return `?${queryString.stringify(params, {
    arrayFormat: 'brackets',
  })}`
}
