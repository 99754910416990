import { isIOS, isMobile } from "react-device-detect";
import useWindowDimensions from "./useWindowDimensions";
import { useEffect, useState } from "react";

export default function useViewportHeight(
  containerRef?: React.RefObject<HTMLDivElement>
) {
  const { width, height } = useWindowDimensions();

  const [offsetTop, setOffsetTop] = useState<number>(0);

  useEffect(() => {
    if (containerRef?.current) {
      setOffsetTop(containerRef.current.offsetTop + getTobBarHeight());
    }
  }, [containerRef?.current, width, height]);

  const getTobBarHeight = () => {
    if (!isMobile) {
      return 0;
    }

    if (isIOS) {
      return 50;
    }
    return 56;
  };

  const viewportHeight = isMobile
    ? `calc(100vh - ${getTobBarHeight()}px)`
    : "100vh";

  return {
    viewportHeight,
    offsetTop,
    getTobBarHeight,
  };
}
