export default {
  login: "/",
  signup: "/signup",
  forgotPassword: "/forgot-password",
  recoverPassword: "/password/:token",
  acceptInvitation: "/invite/:token",
  companyPage: "/:slug",
  companyJobPage: "/:slug/jobs/:job_id",
  companyApplyJobPage: "/:slug/jobs/:job_id/apply",
  dashboard: "/admin",
  choosePlan: "/admin/choose-plan",
  jobs: "/admin/jobs",
  newJob: "/admin/jobs/new",
  manageJob: "/admin/jobs/:id",
  candidatesJob: "/admin/jobs/:id/candidates",
  billings: "/admin/billings",
  website: "/admin/settings/website",
  departments: "/admin/settings/departments",
  newDepartment: "/admin/settings/departments/new",
  manageDepartment: "/admin/departments/:id",
  benefits: "/admin/settings/benefits",
  newBenefit: "/admin/settings/benefits/new",
  manageBenefit: "/admin/benefits/:id",
  users: "/admin/permissions/users",
  newUser: "/admin/permissions/users/new",
  manageUser: "/admin/permissions/users/:id",
  profiles: "/admin/permissions/profiles",
  newProfile: "/admin/permissions/profiles/new",
  manageProfile: "/admin/permissions/profiles/:id",
  candidateLogin: "/candidate/login",
  adminCandidateMyApplications: "/admin/candidate/applications",
  adminCandidateProfile: "/admin/candidate/profile",
};
