import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { JobPipelinesAttributes } from "../../../redux/job/jobApi";
import { Place } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CardContainer from "../ModalComponents/CardContainer";

interface IPersonalDetails {
  candidate: JobPipelinesAttributes["candidates"][number];
}

const PersonalDetails: React.FC<IPersonalDetails> = ({ candidate }) => {
  const renderField = (name: string, value: React.ReactNode) => {
    return (
      <Grid item xs={12} md={6}>
        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
          {name}
        </Typography>
        <Typography sx={{ fontSize: 14 }}>{value}</Typography>
      </Grid>
    );
  };

  const renderFullAddress = () => {
    const { address } = candidate;
    if (!address) return "-";

    const fullAddress = `${address.address_1 || ""} ${
      address.address_2 || ""
    }, ${address.district || ""}, ${address.city || ""} - ${
      address.state || ""
    }, ${address.cep || ""}`;

    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      fullAddress
    )}`;

    return (
      <Box sx={{ display: "flex" }}>
        <Box>
          <Place fontSize="small" />
        </Box>
        <Link
          to={googleMapsUrl}
          target="_blank"
          style={{ textDecoration: "underline" }}
        >
          {fullAddress
            .replace(/,\s*,/g, ", ")
            .replace(/^\s*,|,\s*$/g, "")
            .trim() || "-"}
        </Link>
      </Box>
    );
  };

  const renderCoverLetter = () => {
    if (!candidate.cover_letter) {
      return null;
    }

    return (
      <CardContainer title="Carta de Apresentação">
        <Typography sx={{ fontSize: 14 }}>{candidate.cover_letter}</Typography>
      </CardContainer>
    );
  };

  const renderSumary = () => {
    if (!candidate.summary) {
      return null;
    }

    return (
      <CardContainer title="Sumário Profissional">
        <Typography sx={{ fontSize: 14 }}>{candidate.summary}</Typography>
      </CardContainer>
    );
  };

  const renderAddress = () => {
    if (!candidate.address) {
      return null;
    }

    return (
      <CardContainer title="Endereço">
        <Grid container spacing={2}>
          {renderField("CEP", candidate.address.cep || "-")}
          {renderField("Endereço", candidate.address.address_1 || "-")}
          {renderField("Complemento", candidate.address.address_2 || "-")}
          {renderField("Bairro", candidate.address.district || "-")}
          {renderField("Cidade", candidate.address.city || "-")}
          {renderField("Estado", candidate.address.state || "-")}
          {renderField("Endereço Completo", renderFullAddress())}
        </Grid>
      </CardContainer>
    );
  };

  const renderPhoneNumberField = () => {
    if (!candidate.email) return "-";

    return (
      <Link
        to={`mailto:${candidate.email}`}
        target="_blank"
        style={{ textDecoration: "underline" }}
      >
        {candidate.email}
      </Link>
    );
  };

  const renderContactDetails = () => {
    return (
      <CardContainer title="Informações de contato">
        <Grid container spacing={2}>
          {renderField("Nome", candidate.name || "-")}
          {renderField("Email", renderPhoneNumberField())}
          {candidate.phone_number
            ? renderField("Telefone", candidate.phone_number)
            : null}
        </Grid>
      </CardContainer>
    );
  };

  return (
    <>
      {renderContactDetails()}
      {renderAddress()}
      {renderSumary()}
      {renderCoverLetter()}
    </>
  );
};

export default PersonalDetails;
