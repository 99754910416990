import { useTheme } from "@mui/material";
import { JobStatus } from "../utils/types";
import { jobStatuses, jobStatusesForCandidate } from "../utils/constants";

export default function useJobStatus(
  status: JobStatus,
  forCandidate: boolean = false
) {
  const theme = useTheme();

  const name = forCandidate
    ? jobStatusesForCandidate[status]
    : jobStatuses[status];

  const colors = {
    draft: theme.palette.grey[500],
    published: theme.palette.primary.main,
    standby: theme.palette.warning.main,
    completed: theme.palette.success.main,
  };

  const color = colors[status];

  return {
    name,
    color,
  };
}
