import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { themeDefault } from "./assets/themes";
import "react-multi-carousel/lib/styles.css";
import Routes from "./Routes";
import { ptBR } from "date-fns/locale";
import { setDefaultOptions } from "date-fns/setDefaultOptions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { BrowserRouter as Router } from "react-router-dom";

setDefaultOptions({ locale: ptBR });

function App() {
  return (
    <ThemeProvider theme={themeDefault}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <div className="App">
          <Router>
            <Routes />
          </Router>

          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
