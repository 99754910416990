import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  AccountBox,
  AutoAwesome,
  Business,
  Dashboard,
  ExpandLess,
  ExpandMore,
  Group,
  Language,
  ManageAccounts,
  Paid,
  Person,
  Settings,
  Work,
} from "@mui/icons-material";
import MenuDropdown, {
  IMenuDropdownRef,
  MenuItemDropdown,
} from "./MenuDropdown";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/store";
import { logout } from "../redux/user/userActions";
import { clearLocalStorage } from "../utils/localStorage";
import { clearState, userSelector } from "../redux/user/userSlice";
import { Collapse, Tooltip, Typography } from "@mui/material";
import useWindowDimensions from "../hooks/useWindowDimensions";
import urls from "../utils/urls";
import { closeNav, navSelector, openNav } from "../redux/nav/navSlice";
import usePermission from "../hooks/usePermission";
import CompanySelector from "./CompanySelector";
import Avatar from "./Avatar";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  windowwidth?: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open, windowwidth }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginLeft: windowwidth,
      width: 0,
    },
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface IMenu {
  id: string;
  title: string;
  icon: React.ReactNode;
  link?: string;
  submenus?: IMenu[];
}

function Nav({
  children,
  selected,
}: {
  children: React.ReactNode;
  selected?: string;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { width } = useWindowDimensions();

  const hasPermissionToJobsIndex = usePermission("jobs.index");
  const hasPermissionToUsersIndex = usePermission("users.index");
  const hasPermissionToProfilesIndex = usePermission("profiles.index");
  const hasPermissionToCompanyShow = usePermission("company.show");
  const hasPermissionToDepartmentsIndex = usePermission("departments.index");
  const hasPermissionToBenefitsIndex = usePermission("benefits.index");
  const hasPermissionToBillingsIndex = usePermission("billings.index");

  const { currentUser } = useAppSelector(userSelector);
  const { open } = useAppSelector(navSelector);

  const menuAccountRef = useRef<IMenuDropdownRef>(null);

  const [submenuOpen, setSubmenuOpen] = useState<string>("");

  const isMobile = width < theme.breakpoints.values.sm;

  useEffect(() => {
    if (isMobile) {
      dispatch(closeNav());
    }
  }, [isMobile]);

  const handleDrawerOpen = () => {
    dispatch(openNav());
  };

  const handleDrawerClose = () => {
    dispatch(closeNav());
  };

  const logoutUser = async () => {
    await dispatch(logout());
    clearLocalStorage();
    dispatch(clearState());
    if (!currentUser.company) {
      navigate(urls.candidateLogin);
    } else {
      navigate(urls.login);
    }
  };

  const openSubmenu = (menu: IMenu) => {
    setSubmenuOpen((oldId) => (oldId === menu.id ? "" : menu.id));
  };

  const menu: IMenu[] = useMemo(() => {
    if (!currentUser.id) {
      return [];
    }

    const permissionSubmenus: IMenu[] = [];
    const settingsSubmenus: IMenu[] = [];
    const menu: IMenu[] = [];
    if (currentUser.company) {
      menu.push({
        id: "dashboard",
        title: "Dashboard",
        icon: <Dashboard />,
        link: urls.dashboard,
      });
    } else {
      menu.push({
        id: "candidateMyApplications",
        title: "Minhas Vagas",
        icon: <Work />,
        link: urls.adminCandidateMyApplications,
      });
      menu.push({
        id: "candidateProfile",
        title: "Perfil",
        icon: <Person />,
        link: urls.adminCandidateProfile,
      });
    }
    if (hasPermissionToJobsIndex) {
      menu.push({
        id: "jobs",
        title: "Vagas",
        icon: <Work />,
        link: urls.jobs,
      });
    }
    if (hasPermissionToBillingsIndex) {
      menu.push({
        id: "billings",
        title: "Cobranças",
        icon: <Paid />,
        link: urls.billings,
      });
    }
    if (hasPermissionToUsersIndex || hasPermissionToProfilesIndex) {
      menu.push({
        id: "permissions",
        title: "Permissões",
        icon: <ManageAccounts />,
        submenus: permissionSubmenus,
      });
    }
    if (hasPermissionToUsersIndex) {
      permissionSubmenus.push({
        id: "users",
        title: "Usuários",
        icon: <Group />,
        link: urls.users,
      });
    }
    if (hasPermissionToProfilesIndex) {
      permissionSubmenus.push({
        id: "profiles",
        title: "Perfis",
        icon: <AccountBox />,
        link: urls.profiles,
      });
    }
    if (
      hasPermissionToCompanyShow ||
      hasPermissionToDepartmentsIndex ||
      hasPermissionToBenefitsIndex
    ) {
      menu.push({
        id: "settings",
        title: "Configurações",
        icon: <Settings />,
        submenus: settingsSubmenus,
      });
    }
    if (hasPermissionToCompanyShow) {
      settingsSubmenus.push({
        id: "website",
        title: "Website",
        icon: <Language />,
        link: urls.website,
      });
    }
    if (hasPermissionToDepartmentsIndex) {
      settingsSubmenus.push({
        id: "departments",
        title: "Departamentos",
        icon: <Business />,
        link: urls.departments,
      });
    }
    if (hasPermissionToBenefitsIndex) {
      settingsSubmenus.push({
        id: "benefits",
        title: "Benefícios",
        icon: <AutoAwesome />,
        link: urls.benefits,
      });
    }
    return menu;
  }, [
    currentUser,
    hasPermissionToUsersIndex,
    hasPermissionToProfilesIndex,
    hasPermissionToJobsIndex,
    hasPermissionToCompanyShow,
    hasPermissionToDepartmentsIndex,
    hasPermissionToBenefitsIndex,
  ]);

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <AppBar
        position="fixed"
        open={open}
        windowwidth={width}
        sx={{ display: { xs: open ? "none" : "block", sm: "block" } }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            Title
          </Typography> */}
          <Box sx={{ flexGrow: 1 }} />
          <Typography>{currentUser.first_name}</Typography>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            onClick={(e) => menuAccountRef?.current?.open(e)}
          >
            {currentUser.company ? (
              <Avatar
                src={currentUser.company.logo}
                name={currentUser.company.name}
                disableBorder
              />
            ) : (
              <Avatar
                src={currentUser.avatar}
                name={currentUser.name}
                nameInitials={currentUser.first_name}
                disableBorder
              />
            )}
          </IconButton>
          <MenuDropdown id="menu-account" ref={menuAccountRef}>
            <MenuItemDropdown onClick={() => logoutUser()}>
              Sair
            </MenuItemDropdown>
          </MenuDropdown>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <CompanySelector />
        <List>
          {menu.map((item, index) => (
            <Fragment key={`${item.title}-${index}`}>
              <Link
                to={item?.link || ""}
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={() => {
                  if (isMobile && item?.link) {
                    handleDrawerClose();
                  }
                }}
              >
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() => openSubmenu(item)}
                >
                  <ListItemButton
                    selected={selected === item.id}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <Tooltip title={!open ? item.title : ""} placement="right">
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      primary={item.title}
                      sx={{
                        opacity: open ? 1 : 0,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    />
                    {item.submenus && item.submenus.length > 0 ? (
                      submenuOpen === item.id ? (
                        <ExpandLess
                          sx={{
                            display: open ? "block" : "none",
                            color: "rgba(0, 0, 0, 0.54)",
                          }}
                        />
                      ) : (
                        <ExpandMore
                          sx={{
                            display: open ? "block" : "none",
                            color: "rgba(0, 0, 0, 0.54)",
                          }}
                        />
                      )
                    ) : null}
                  </ListItemButton>
                </ListItem>
              </Link>
              {item.submenus && (
                <Collapse
                  in={submenuOpen === item.id}
                  timeout="auto"
                  unmountOnExit
                >
                  {item.submenus.map((submenu, i) => (
                    <Link
                      to={submenu?.link || ""}
                      key={`${submenu.title}-${i}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={() => {
                        if (isMobile) {
                          handleDrawerClose();
                        }
                      }}
                    >
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <ListItemButton
                          selected={selected === submenu.id}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            pl: open ? 6 : 5,
                          }}
                        >
                          <Tooltip
                            title={!open ? submenu.title : ""}
                            placement="right"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 2 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              {submenu.icon}
                            </ListItemIcon>
                          </Tooltip>
                          <ListItemText
                            primary={submenu.title}
                            sx={{
                              opacity: open ? 1 : 0,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  ))}
                </Collapse>
              )}
            </Fragment>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 1, sm: 3 },
          display: { xs: open ? "none" : "block", sm: "block" },
          width: "100%",
          overflow: "hidden",
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}

export default Nav;
