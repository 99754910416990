import React, { useRef, useMemo } from "react";
import { Box, Tooltip, Typography, colors } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../app/store";
import { UserAttributes, userSelector } from "../redux/user/userSlice";
import { navSelector } from "../redux/nav/navSlice";
import MenuDropdown, {
  IMenuDropdownRef,
  MenuItemDropdown,
} from "./MenuDropdown";
import { updateMyCurrentCompany } from "../redux/user/userActions";
import { useNavigate } from "react-router-dom";
import urls from "../utils/urls";
import Avatar from "./Avatar";

const CompanySelector = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const companySelectorRef = useRef<IMenuDropdownRef>(null);

  const { currentUser } = useAppSelector(userSelector);
  const { open } = useAppSelector(navSelector);

  const handleUpdateCurrentCompany = (companyId: number | null) => {
    dispatch(updateMyCurrentCompany(companyId)).then((value) => {
      if (value.meta.requestStatus !== "rejected") {
        if (companyId) {
          navigate(urls.dashboard);
        } else {
          navigate(urls.adminCandidateMyApplications);
        }
        window.location.reload();
      }
    });
  };

  const renderCompany = (
    company: UserAttributes["companies"][0] | UserAttributes["company"] | null,
    alwaysShow = false
  ) => {
    const name = company?.name || "Meu Perfil";
    const nameInitials = company ? name : currentUser.first_name;
    const logo = company?.logo || currentUser.avatar;

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: open ? "start" : "center",
        }}
      >
        <Tooltip title={!open ? name : ""} placement="right">
          <Avatar src={logo} name={name} nameInitials={nameInitials} />
        </Tooltip>
        {(open || alwaysShow) && (
          <Typography
            sx={{
              fontSize: 14,
              ml: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </Typography>
        )}
      </Box>
    );
  };

  const canSeeDropdown = useMemo(() => {
    return currentUser.companies.length > 1 || currentUser.is_candidate;
  }, [currentUser]);

  return currentUser.companies.length > 0 ? (
    <>
      <Box
        sx={{
          mt: 1,
          mx: open ? 2 : 1,
          py: 1,
          px: open ? 1 : 0,
          border: open ? 1 : 0,
          borderColor: colors.grey[300],
          borderRadius: 1,
          cursor: canSeeDropdown ? "pointer" : "auto",
        }}
        onClick={(e) => canSeeDropdown && companySelectorRef?.current?.open(e)}
      >
        {renderCompany(currentUser.company)}
      </Box>
      <MenuDropdown id="company-selector" ref={companySelectorRef}>
        {currentUser.is_candidate && (
          <MenuItemDropdown onClick={() => handleUpdateCurrentCompany(null)}>
            {renderCompany(null, true)}
          </MenuItemDropdown>
        )}
        {currentUser.companies.map((company) => (
          <MenuItemDropdown
            key={company.id}
            onClick={() => handleUpdateCurrentCompany(company.id)}
          >
            {renderCompany(company, true)}
          </MenuItemDropdown>
        ))}
      </MenuDropdown>
    </>
  ) : null;
};

export default CompanySelector;
