import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormikHelpers } from "formik";
import * as candidateNoteApi from "./candidateNoteApi";
import statusCodes from "../../utils/statusCodes";
import setFormikErrors from "../../utils/setFormikErrors";

export const getCandidateNotes = createAsyncThunk(
  "candidateNotes/index",
  async (candidateId: number, thunkAPI) => {
    try {
      const response = await candidateNoteApi.index(candidateId);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createCandidateNote = createAsyncThunk(
  "candidateNotes/create",
  async (
    {
      params,
      formikBag,
    }: {
      params: candidateNoteApi.CandidateNoteParams;
      formikBag?: FormikHelpers<candidateNoteApi.CandidateNoteParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await candidateNoteApi.create(params);
      const { data } = response;
      if (response.status === statusCodes.created) {
        return data;
      } else {
        if (formikBag) {
          setFormikErrors(data?.errors, formikBag);
        }
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateCandidateNote = createAsyncThunk(
  "candidateNotes/update",
  async (
    {
      params,
      formikBag,
    }: {
      params: candidateNoteApi.CandidateNoteParams;
      formikBag: FormikHelpers<candidateNoteApi.CandidateNoteParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await candidateNoteApi.update(params);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const destroyCandidateNote = createAsyncThunk(
  "candidateNotes/destroy",
  async (id: string, thunkAPI) => {
    try {
      const response = await candidateNoteApi.destroy(id);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
