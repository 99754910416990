import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormikHelpers } from "formik";
import * as jobApi from "./jobApi";
import statusCodes from "../../utils/statusCodes";
import setFormikErrors from "../../utils/setFormikErrors";

export const getJobs = createAsyncThunk(
  "jobs/index",
  async (company_id: number, thunkAPI) => {
    try {
      const response = await jobApi.index(company_id);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createJob = createAsyncThunk(
  "jobs/create",
  async (
    {
      params,
      formikBag,
    }: {
      params: jobApi.JobParams;
      formikBag: FormikHelpers<jobApi.JobParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await jobApi.create(params);
      const { data } = response;
      if (response.status === statusCodes.created) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const showJob = createAsyncThunk(
  "jobs/show",
  async (id: string, thunkAPI) => {
    try {
      const response = await jobApi.show(id);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateJob = createAsyncThunk(
  "jobs/update",
  async (
    {
      params,
      formikBag,
    }: {
      params: jobApi.JobParams;
      formikBag: FormikHelpers<jobApi.JobParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await jobApi.update(params);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const destroyJob = createAsyncThunk(
  "jobs/destroy",
  async (id: string, thunkAPI) => {
    try {
      const response = await jobApi.destroy(id);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const publishJob = createAsyncThunk(
  "jobs/publish",
  async (
    {
      id,
      formikBag,
    }: {
      id: string;
      formikBag: FormikHelpers<jobApi.JobParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await jobApi.publish(id);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateStatus = createAsyncThunk(
  "jobs/updateStatus",
  async (
    {
      id,
      status,
      formikBag,
    }: {
      id: string;
      status: string;
      formikBag: FormikHelpers<jobApi.JobParams>;
    },
    thunkAPI
  ) => {
    try {
      const response = await jobApi.updateStatus(id, status);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        setFormikErrors(data?.errors, formikBag);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getPipelines = createAsyncThunk(
  "jobs/pipelines",
  async (id: string, thunkAPI) => {
    try {
      const response = await jobApi.pipelines(id);
      const { data } = response;
      if (response.status === statusCodes.ok) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
