import React from "react";
import { JobPipelinesAttributes } from "../../redux/job/jobApi";
import { Rating as BaseRating } from "@mui/material";
import { FormikProps, getIn } from "formik";
import { toast } from "react-toastify";
import { updateCandidate } from "../../redux/candidate/candidateActions";
import { useAppDispatch } from "../../app/store";

interface IRating {
  formik: FormikProps<any>;
  candidate: JobPipelinesAttributes["candidates"][number];
  pipelineIndex: number;
  candidateIndex: number;
}

const Rating: React.FC<IRating> = ({
  formik,
  candidate,
  pipelineIndex,
  candidateIndex,
}) => {
  const dispatch = useAppDispatch();

  const field = `pipelines.${pipelineIndex}.candidates.${candidateIndex}.rating`;

  const updateRating = (rating: number) => {
    const oldRating = getIn(formik.values, field);
    formik.setFieldValue(field, rating);

    dispatch(updateCandidate({ params: { id: candidate.id, rating } })).then(
      (value) => {
        if (value.meta.requestStatus === "rejected") {
          formik.setFieldValue(field, oldRating);
          toast.error("Não foi possível avaliar o candidato, tente novamente!");
        }
      }
    );
  };

  return (
    <BaseRating
      value={getIn(formik.values, field)}
      onChange={(e, newValue) => updateRating(newValue || 0)}
    />
  );
};

export default Rating;
