import { AxiosResponse } from "axios";
import requestHelper from "../../utils/requestHelper";

export interface CustomerSessionReponse {
  client_secret: string;
}

export interface CustomerPortalSessionReponse {
  url: string;
}

const customerPortalSession = async (): Promise<
  AxiosResponse<CustomerPortalSessionReponse>
> => {
  return await requestHelper
    .get(`stripe/customer-portal-session`)
    .catch((e) => e.response);
};

export { customerPortalSession };
